<template>
  <div>
    <el-card style="height: 360px">
      <div style="color: #000; font-weight: 700; font-size: 16px; margin-bottom: 10px">进行中的考试</div>

      <el-table
        v-if="tableData.length > 0"
        :data="tableData"
        :header-cell-style="{
          background: '#f2f7fb',
          color: '#000',
          'font-weight': '700',
          'font-size': '14px',
          'line-height': '32px',
        }"
        style="width: 100%"
      >
        <el-table-column label="考试名称" prop="title" show-overflow-tooltip>
          <template v-slot="scope">
            <detail-link :query="{ examId: scope.row.id }" :title="scope.row.title" to="ExamWatchDetail" />
          </template>
        </el-table-column>

        <el-table-column label="正在考试" align="center" prop="procCount" width="100px" show-overflow-tooltip />

        <el-table-column label="考完人数" align="center" width="100px" prop="handCount" show-overflow-tooltip />
      </el-table>

      <div v-else class="t-empty">
        <el-empty description="啊哦，暂无正在进行中的考试！" />
      </div>
    </el-card>
  </div>
</template>

<script>
import permission from '@/directive/permission'
import { postData } from '@/api/common'

export default {
  name: 'CardExamList',
  components: {},
  directives: { permission },
  data() {
    return {
      tableData: [],
      listQuery: {
        current: 1,
        size: 5,
        params: {
          title: '',
        },
      },
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      postData('/api/stat/exam/dash-exam-list', this.listQuery).then((res) => {
        this.tableData = res.data
      })
    },
  },
}
</script>
