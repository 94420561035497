<template>
  <span>
    <span v-if="costPoints == 0 && salePrice > 0"> ￥ {{ salePrice }} </span>
    <span v-if="costPoints > 0 && salePrice == 0"> {{ costPoints }}积分 </span>
    <span v-if="costPoints > 0 && salePrice > 0"> ￥ {{ salePrice }} + {{ costPoints }}积分 </span>
  </span>
</template>

<script>
export default {
  name: 'PriceShow',
  props: {
    costPoints: {
      type: Number,
      default: 0,
    },
    salePrice: {
      type: Number,
      default: 0,
    },
  },
}
</script>
