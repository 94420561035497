<template>
  <div>
    <web-table ref="pagingTable" :options="options" :list-query="listQuery" @select-changed="handleSelect">
      <template #filter-content>
        <div style="display: flex; align-items: center">
          <div style="flex-grow: 1; margin: 10px 0">
            <el-button
              :disabled="selections.length === 0 || readState === 1"
              type="primary"
              size="small"
              icon="el-icon-check"
              @click="handleMark"
            >
              标记已读
            </el-button>

            <el-button
              :disabled="selections.length === 0"
              type="danger"
              size="small"
              icon="el-icon-delete"
              @click="handelDelete"
            >
              删除
            </el-button>
          </div>

          <div style="width: 300px; display: flex; justify-content: flex-end">
            <el-radio-group v-model="readState" size="small" plain>
              <el-radio-button :label="0">未读</el-radio-button>
              <el-radio-button :label="1">已读</el-radio-button>
            </el-radio-group>
          </div>
        </div>
      </template>
      <template #data-columns>
        <el-table-column show-overflow-tooltip label="标题" prop="title" width="200px" />

        <el-table-column label="内容" prop="content" show-overflow-tooltip>
          <template v-slot="scope">
            <el-link @click="showDetail(scope.row.id)">{{ scope.row.content }}</el-link>
          </template>
        </el-table-column>

        <el-table-column label="发送时间" align="center" width="180px" prop="createTime" />
      </template>
    </web-table>

    <el-dialog
      :close-on-click-modal="false"
      :append-to-body="true"
      :visible.sync="dialogVisible"
      title="消息详情"
      width="60%"
    >
      <h3>{{ detail.title }}</h3>
      <small>发送时间：{{ detail.createTime }}</small>
      <el-divider />
      <p class="notice" v-html="$options.filters.formatRichText(detail.content)" />

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { fetchDetail, markAsRead, userDelete } from '@/api/sys/user/im'
import store from '@/store'

export default {
  data() {
    return {
      readState: 0,

      listQuery: {
        current: 1,
        size: 10,
        params: {
          readState: '',
        },
      },

      options: {
        multi: true,
        // 列表请求URL
        listUrl: '/api/sys/user/msg/paging',
      },

      dialogVisible: false,
      loading: false,
      detail: {},
      selections: [],
    }
  },
  watch: {
    readState: {
      handler(val) {
        this.listQuery.params.readState = val
      },
    },
  },
  methods: {
    // 消息详情
    showDetail(id) {
      this.dialogVisible = true
      fetchDetail(id).then((res) => {
        this.detail = res.data
        this.refresh()
      })
    },

    // 删除站内信
    handelDelete() {
      this.$confirm('确认要删除选定的信息吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
      }).then(() => {
        userDelete({ ids: this.selections }).then(() => {
          this.$message.success('删除成功！')
          this.refresh()
        })
      })
    },

    // 标记为已读
    handleMark() {
      markAsRead({ ids: this.selections }).then(() => {
        this.$message.success('操作成功！')
        this.refresh()
      })
    },

    /**
     * 选定操作
     * @param obj
     */
    handleSelect(obj) {
      this.selections = obj.ids
    },

    // 刷新计数
    refresh() {
      // 立马刷新
      this.$refs.pagingTable.getList()
      // 刷新全局统计
      store.dispatch('user/fetchMsg')
    },
  },
}
</script>

<style scoped>
::v-deep a {
  color: #1890ff !important;
  font-weight: 700;
}
</style>
