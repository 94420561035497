<template>
  <div id="full-box">
    <div v-for="(group, index) in paperData.groupList" :key="group.id">
      <div class="group-tt">
        第{{ index + 1 }}大题，{{ group.title }}(共{{ group.quCount }}题,每题{{ group.perScore }}分,合计{{
          group.totalScore
        }}分)
      </div>
      <div v-for="qu in group.quList" :key="qu.id">
        <qu-item-exam :value="qu" :show-type="true" @fill="handleSave" @focus="handleFocus" @action="handleAction" />
      </div>
    </div>
  </div>
</template>

<script>
import { scrollToDom } from '@/utils/scroll-to'
import { fillAnswer } from '@/api/paper/exam'
export default {
  name: 'ExamFullMode',
  props: {
    paper: {
      type: Object,
      default: function () {
        return {
          leftSeconds: 99999,
        }
      },
    },
  },
  data() {
    return {
      pageLoading: false,
      // 试卷信息
      paperData: {
        leftSeconds: 99999,
      },
    }
  },
  watch: {
    // 检测查询变化
    paper: {
      handler() {
        this.fillPaper()
      },
      deep: true,
    },
  },

  created() {
    this.fillPaper()
  },

  methods: {
    // 填充试卷信息
    fillPaper() {
      if (this.paper && this.paper.id) {
        this.paperData = this.paper
      }
    },

    // 答题回调
    handleSave(data) {
      // 提交保存
      fillAnswer(data).then((res) => {
        this.$emit('saved', res.data)
      })
    },

    // 获得焦点
    handleFocus(quId, subId) {
      this.$emit('focus', quId, subId || '')
    },

    // 到指定位置
    focus(quId) {
      const dom = document.querySelector('#exam-body')
      const anchor = document.getElementById(`qu-${quId}`)
      const to = anchor.offsetTop - 20
      // 滑动到指定位置
      scrollToDom(dom, to, 600, function () {})
    },
    // 操作变化
    handleAction() {
      this.$emit('action')
    },
  },
}
</script>

<style scoped>
.group-tt {
  background: #0a84ff;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 10px;
  color: #fff;
}
</style>
