<template>
  <div>
    <div class="filter-container" style="display: flex; align-items: center; margin-bottom: 20px; flex-wrap: wrap">
      <slot name="filter-content" />
    </div>

    <div v-if="dataList.total > 0">
      <div v-loading="listLoading" :class="`grid-box-${options.lineCount}`" class="grid-box">
        <template v-for="(item, index) in dataList.records">
          <div :key="item.id || index" class="grid-box-inner">
            <slot :data="item" name="data-item" />
          </div>
        </template>
      </div>

      <div style="text-align: center; display: flex; justify-content: center">
        <pagination
          v-show="dataList.pages > 1"
          :total="dataList.total"
          :page.sync="listQuery.current"
          :auto-scroll="autoScroll"
          :limit.sync="listQuery.size"
          @pagination="getList"
        />
      </div>
    </div>
    <div v-else style="display: flex; align-items: center; height: 60vh; justify-content: center">
      <el-empty description="暂无相关数据！" />
    </div>
  </div>
</template>

<script>
import { postData } from '@/api/common'
import permission from '@/directive/permission/index.js' // 权限判断指令

export default {
  name: 'DataGrid',
  directives: { permission },
  components: {},
  // 组件入参
  props: {
    options: {
      type: Object,
      default: () => {
        return {
          keyId: 'id',
          lineCount: 8,
          add: {
            enable: false,
            permission: '',
            router: {},
          },
          edit: {
            enable: false,
            permission: '',
            router: {},
          },
          delete: {
            enable: false,
            permission: '',
            url: '',
          },
          // 批量操作
          multiActions: [],
          // 列表请求URL
          listUrl: '',
          // 删除请求URL
          deleteUrl: '',
          // 启用禁用
          stateUrl: '',
          // 可批量操作
          multi: false,
        }
      },
    },

    // 列表查询参数
    listQuery: {
      type: Object,
      default: () => {
        return {
          current: 1,
          size: 30,
          params: {},
          t: 0,
        }
      },
    },

    autoScroll: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // 接口数据返回
      dataList: {
        total: 0,
      },
      // 数据加载标识
      listLoading: true,
      // 选定和批量操作
      selectedIds: [],
      selectedObjs: [],
      // 显示批量操作
      multiShow: false,
    }
  },
  watch: {
    // 检测查询变化
    listQuery: {
      handler() {
        this.getList().then(() => {
          this.$emit('query-change')
        })
      },
      deep: true,
    },
  },
  created() {
    this.getList()
  },
  methods: {
    // 获取选定的key
    getRowKeys(row) {
      if (this.options.keyId) {
        return row[this.options.keyId]
      }
      return row['id']
    },

    // 添加
    optAdd() {
      if (!this.options.add || !this.options.add.enable) {
        return
      }
      // 跳转的
      if (this.options.add.router) {
        this.$router.push(this.options.add.router)
      }
      this.$emit('add')
    },

    // 修改
    optEdit() {
      if (!this.options.edit || !this.options.edit.enable) {
        return
      }

      // 跳转的
      if (this.options.edit.router) {
        const router = this.options.edit.router
        const params = router.params
        params.id = this.selectedIds[0]
        router.params = params
        this.$router.push(router)
      }
      this.$emit('edit', this.selectedIds[0], this.selectedObjs[0])
    },

    // 删除
    optDelete() {
      if (!this.options.delete || !this.options.delete.enable) {
        return
      }

      if (this.options.delete.url) {
        // 删除
        this.$confirm('确定要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          postData(this.options.delete.url, { ids: this.selectedIds }).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.getList()
          })
        })
      }
      this.$emit('delete', this.selectedIds)
    },

    // 批处理回调
    optAction(item) {
      if (item.value === 'enable') {
        // 修改状态
        postData(item.url, { ids: this.selectedIds, state: 0 }).then((response) => {
          if (response.code === 0) {
            this.$message({
              type: 'success',
              message: '状态修改成功!',
            })

            // 重新搜索
            this.getList()
          }
        })
        return
      }

      if (item.value === 'state') {
        // 修改状态
        postData(item.url, { ids: this.selectedIds, state: item.state }).then((response) => {
          if (response.code === 0) {
            this.$message({
              type: 'success',
              message: '状态修改成功!',
            })

            // 重新搜索
            this.getList()
          }
        })
        return
      }

      if (item.value === 'disable') {
        // 修改状态
        postData(item.url, { ids: this.selectedIds, state: 1 }).then((response) => {
          if (response.code === 0) {
            this.$message({
              type: 'success',
              message: '状态修改成功!',
            })

            // 重新搜索
            this.getList()
          }
        })
        return
      }

      // 向外回调的操作
      this.$emit('multi-actions', { opt: item.value, ids: this.selectedIds, objs: this.selectedObjs })
    },

    /**
     * 添加数据跳转
     */
    handleAdd() {
      if (this.options.addRoute) {
        this.$router.push({ name: this.options.addRoute, params: {} })
        return
      }
      console.log('未设置添加数据跳转路由！')
    },

    /**
     * 查询数据列表
     */
    getList() {
      return new Promise((resolve) => {
        this.listLoading = true
        this.listQuery.t = new Date().getTime()
        postData(this.options.listUrl, this.listQuery).then((response) => {
          this.dataList = response.data
          this.listLoading = false
          // 回填选定状态
          this.$nextTick(() => {
            this.toggleSelection()
          })
          resolve()
        })
      })
    },

    // 下一页
    nextPage() {
      let current = this.listQuery.current
      if (current < this.dataList.pages) {
        current += 1
      } else {
        // 重头再来
        current = 1
      }

      this.listQuery.current = current
    },

    /**
     * 删除数据
     */
    handleDelete() {
      if (this.selectedIds.length === 0) {
        this.$message({
          message: '请至少选择一条数据！',
          type: 'warning',
        })
        return
      }

      // 删除
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        postData(this.options.deleteUrl, { ids: this.selectedIds }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.clearSelection()
          this.getList()
        })
      })
    },

    /**
     * 列表多选操作
     * @param val
     */
    handleSelection(rows) {
      rows.forEach((row) => {
        const result = this.selectedIds.some((ele) => ele === row.id)
        if (!result) {
          this.selectedIds.push(row.id)
          this.selectedObjs.push(row)
        }
      })

      this.$emit('select-changed', { ids: this.selectedIds, objs: this.selectedObjs })
    },

    tableSortChange(column) {
      this.listQuery.pageNo = 1
      if (column.order === 'descending') {
        this.listQuery.orders = [{ column: column.prop, asc: false }]
      } else {
        this.listQuery.orders = [{ column: column.prop, asc: true }]
      }
      this.getList()
    },

    // 清理选择的
    clearSelection() {
      this.$refs.table.clearSelection()
      this.selectedIds = []
      this.selectedObjs = []
    },

    // 回填已经选定的值
    toggleSelection() {
      // 没有待选定的
      if (
        !this.selectedIds ||
        this.selectedIds.length === 0 ||
        !this.dataList.records ||
        this.dataList.records.length === 0
      ) {
        return
      }

      if (!this.$refs.table) {
        return
      }

      this.dataList.records.forEach((row) => {
        if (this.selectedIds.some((ele) => ele === row.id)) {
          this.$refs.table.toggleRowSelection(row, true)
        }
      })
    },
  },
}
</script>

<style scoped>
::v-deep .filter-container .filter-item {
  margin-left: 10px;
}

::v-deep .filter-container .filter-item:first-child {
  margin-left: 0;
}

::v-deep .pagination-container {
  background: transparent !important;
}
</style>
