import { download, post } from '@/utils/request'

/**
 * 试卷列表
 * @param data
 */
export function listPaper(examId, userId) {
  return post('/api/paper/paper/paging', { current: 1, size: 100, params: { examId: examId, userId: userId } })
}

/**
 * 考试截图
 * @param data
 */
export function listCaptures(paperId) {
  return post('/api/paper/capture/list', { paperId: paperId })
}

/**
 * 保存阅卷
 * @param data
 */
export function reviewPaper(data) {
  return post('/api/paper/paper/review-paper', data)
}

/**
 * 导出试卷
 * @param data
 */
export function exportZip(data) {
  return post('/api/paper/paper/export-zip', data)
}

/**
 * 导出试卷
 * @param data
 */
export function exportPdf(data, fileName) {
  return download('/api/paper/paper/export-pdf', data, fileName)
}

/**
 * 更改考试记录
 * @param data
 * @returns {Promise}
 */
export function markAs(data) {
  return post('/api/paper/paper/mark-as', data)
}

/**
 * 删除考试记录
 * @param data
 * @returns {Promise}
 */
export function deletePaper(data) {
  return post('/api/paper/paper/delete', data)
}

/**
 * 获取考试剩余时间
 * @param paperId
 * @returns {Promise}
 */
export function leftTime(paperId) {
  return post('/api/paper/paper/left-time', { id: paperId })
}

/**
 * 考核留痕记录导出
 * @param data
 */
export function exportCheck(data, fileName) {
  return download('/api/stat/exam/recordExport', data, fileName)
}
