<template>
  <watch-frame :exam-id="examId" :line-count="8" @stat="syncStat">
    <template v-slot:user-item="{ data }">
      <div class="watch-box-item">
        <el-image :src="data.image" fit="cover" style="width: 100%; height: 100px">
          <div slot="error" class="img-err">
            <i class="el-icon-user" />
          </div>
        </el-image>
      </div>
    </template>
  </watch-frame>
</template>

<script>
import WatchFrame from '@/views/admin/exam/watch/components/WatchFrame/index.vue'

export default {
  name: 'WatchProcess',
  components: { WatchFrame },
  props: {
    examId: String,
  },
  methods: {
    // 刷新统计
    syncStat(data) {
      this.$emit('stat', data)
    },
  },
}
</script>

<style scoped>
.watch-box-item {
  display: flex;
  justify-content: center;
  background: #f5f7fa;
  padding: 2px;
}

::v-deep .img-err {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-err i {
  font-size: 36px;
  color: #ccc;
}
</style>
