import UserLayout from '@/layout/UserLayout'

const ucRouter = {
  path: '/pages/uc',
  component: UserLayout,
  hidden: true,
  redirect: '/pages/uc/info',
  children: [
    {
      path: 'info',
      component: () => import('@/views/web/ucenter/info'),
      name: 'UserCenter',
      meta: { title: '修改资料', noCache: true, activeMenu: '/pages/uc' },
    },
    {
      path: 'pass',
      component: () => import('@/views/web/ucenter/pass'),
      name: 'UserPass',
      meta: { title: '修改密码', noCache: true, activeMenu: '/pages/uc' },
    },
    {
      path: 'bind',
      component: () => import('@/views/web/ucenter/bind'),
      name: 'UserBind',
      meta: { title: '账号绑定', noCache: true, activeMenu: '/pages/uc' },
    },
    {
      path: 'real',
      component: () => import('@/views/web/ucenter/real'),
      name: 'UserReal',
      meta: { title: '账号绑定', noCache: true, activeMenu: '/pages/uc' },
    },
    {
      path: 'points',
      component: () => import('@/views/web/ucenter/points'),
      name: 'UserPointsLog',
      meta: { title: '积分日志', noCache: true, activeMenu: '/pages/uc' },
    },
    {
      path: 'im',
      component: () => import('@/views/web/ucenter/im'),
      name: 'UserIm',
      meta: { title: '站内信', noCache: true, activeMenu: '/pages/uc' },
    },
  ],
}
export default ucRouter
