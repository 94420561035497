// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/web/images/ubg2.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.user-infocon[data-v-79d5de29] {\r\n  height: 145px;\r\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat top center;\r\n  background-size: 100% 100%;\n}\n.user-infocon[data-v-79d5de29] {\r\n  padding: 0 85px;\n}\n.user-infocon .bg[data-v-79d5de29] {\r\n  display: -webkit-box;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  padding-top: 40px;\n}\n.user-infocon .user-img[data-v-79d5de29] {\r\n  border: 2px solid #eee;\r\n  width: 130px;\r\n  height: 130px;\r\n  overflow: hidden;\r\n  position: relative;\r\n  border-radius: 5px;\n}\n.user-infocon .user-img img[data-v-79d5de29] {\r\n  width: 130px;\r\n  height: 130px;\r\n  overflow: hidden;\r\n  position: absolute;\r\n  top: 50%;\r\n  left: 50%;\r\n  -webkit-transform: translate(-50%, -50%);\r\n  transform: translate(-50%, -50%);\n}\n.user-infocon .infor[data-v-79d5de29] {\r\n  margin: 10px 0 0 35px;\n}\n.user-infocon .infor .name[data-v-79d5de29] {\r\n  font-size: 14px;\r\n  font-weight: 700;\r\n  color: #fff;\r\n  margin-top: 0;\n}\n.user-infocon .infor .class[data-v-79d5de29] {\r\n  font-size: 14px;\r\n  color: #fff;\n}\n.user-infocon .infor .class span[data-v-79d5de29]:first-child:after {\r\n  content: '|';\r\n  font-size: 14px;\r\n  color: #fff;\r\n  position: absolute;\r\n  right: -15px;\r\n  top: 0;\n}\n.edit-con[data-v-79d5de29] {\r\n  background: #fff;\r\n  display: -webkit-box;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -webkit-box-align: center;\r\n      -ms-flex-align: center;\r\n          align-items: center;\r\n  -webkit-box-pack: center;\r\n      -ms-flex-pack: center;\r\n          justify-content: center;\r\n  text-align: center;\r\n  color: #888;\r\n  right: 0;\r\n  z-index: 888;\r\n  width: 20px;\r\n  height: 20px;\r\n  position: absolute;\r\n  font-size: 16px;\n}\r\n", ""]);
// Exports
module.exports = exports;
