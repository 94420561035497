import { download, post } from '@/utils/request'

/**
 * 题库详情
 * @param id
 */
export function fetchDetail(id) {
  return post('/api/exam/exam/detail', { id: id })
}

/**
 * 简略详情
 * @param id
 */
export function simpleDetail(id) {
  return post('/api/exam/exam/simple-detail', { id: id })
}

/**
 * 整体统计
 * @param id
 */
export function totalStatistics(id) {
  return post('/api/stat/exam/overview', { id: id })
}

/**
 *统计-理论分页
 * @param data
 */
export function theoryList(data) {
  return post('/api/stat/exam/user-paging', data)
}

/**
 * 保存题库
 * @param data
 */
export function saveData(data) {
  return post('/api/exam/exam/save', data)
}

/**
 * 题库详情
 * @param data
 */
export function fetchList(data) {
  return post('/api/exam/exam/paging', data)
}

/**
 * 校验考试信息
 * @param id
 * @returns {*}
 */
export function checkInfo(id) {
  return post('/api/exam/exam/check-info', { id: id })
}

/**
 * 发送考试安排
 * @param data
 * @returns {Promise}
 */
export function prepareNotify(data) {
  return post('/api/exam/exam/prepare-notify', data)
}

/**
 * 创建预付费订单
 * @param id
 * @returns {Promise}
 */
export function buy(examId) {
  return post('/api/exam/buy/init', { examId: examId })
}

/**
 * 根据考试ID列表获得考试标题
 * @param data
 * @returns {Promise}
 */
export function listTitles(data) {
  return post('/api/exam/exam/list-titles', data)
}

/**
 * 根据试卷加载大题列表，用于阅卷配置
 * @param tmplId
 * @returns {Promise}
 */
export function listTmplGroup(tmplId) {
  return post('/api/tmpl/group/list-group', { id: tmplId })
}

/**
 * 根据试卷加载需要阅卷的题目数量
 * @param tmplId
 * @returns {Promise}
 */
export function countReviewQu(tmplId) {
  return post('/api/tmpl/group/count-review-qu', { id: tmplId })
}

/**
 * 根据试卷加载试题列表用于阅卷配置
 * @param tmplId
 * @returns {Promise}
 */
export function listTmplQu(tmplId) {
  return post('/api/tmpl/group/list-qu', { id: tmplId })
}

/**
 * 修改状态
 * @param examId
 * @param state
 * @returns {Promise}
 */
export function updateState(examId, state) {
  return post('/api/exam/exam/state', { id: examId, state: state })
}

/**
 * 发布成绩
 * @param examId
 * @param state
 * @returns {Promise}
 */
export function achievement(examId) {
  return post('/api/exam/exam/scorePublish', { id: examId })
}

/**
 * 新增细目表
 * @param tmplId
 * @returns {Promise}
 */
export function addDetailTable(data) {
  return post('/api/repo/chapter/insert', data)
}

/**
 * 编辑细目表
 * @param tmplId
 * @returns {Promise}
 */
export function editDetailTable(data) {
  return post('/api/repo/chapter/update', data)
}

/**
 *细目表（树状）
 * @param tmplId
 * @returns {Promise}
 */
export function dataList(data) {
  return post('/api/repo/chapter/list/tree', data)
}

/**
 *细目表（删除）
 * @param tmplId
 * @returns {Promise}
 */
export function delData(data) {
  return post('/api/tmpl/tmpl/delete/breakDown', data)
}

/**
 * 导出细目表
 * @param data
 * @returns {Promise}
 */
export function exportSheet(data) {
  return download('/api/repo/chapter/export', data, '细目表-导出.xlsx')
}

/**
 * 下载细目表
 * @param data
 * @returns {Promise}
 */
export function downSheet(data) {
  return download('/api/repo/chapter/download/template', data, '细目表模板.xlsx')
}

/**
 * 导出试题
 * @param data
 * @returns {Promise}
 */
export function exportTestSheet(data) {
  return download('/api/repo/chapter/export/qu', data, '试题-导出.xlsx')
}

/**
 * 下载试题
 * @param data
 * @returns {Promise}
 */
export function downTestSheet(data) {
  return download('/api/repo/chapter/download/qu/template', data, '试题模板.xlsx')
}

/**
 * 下载配分及评分要求
 * @param data
 * @returns {Promise}
 */
export function downScore(data) {
  return download('/api/qu/qu/download/template', data, '配分及评分要求.xlsx')
}

/**
 * 获取等级和名称
 * @param data
 * @returns {Promise}
 */
export function getLevel(data) {
  return post('/api/repo/chapter/hint/level', data)
}

/**
 * 获取计划
 * @param data
 * @returns {Promise}
 */
export function planData(data) {
  return post('/api/plan/plan/paging', data)
}

/**
 * 获取小组用户
 * @param data
 * @returns {Promise}
 */
export function groupUser(data) {
  return post('/api/exam/exam/GroupUserByGroupId', data)
}

/**
 * 修改时添加学生
 * @param data
 * @returns {Promise}
 */
export function editUser(data) {
  return post('/api/exam/exam/updateExamStudent', data)
}

/**
 * 维护学生准考证号
 * @param data
 * @returns {Promise}
 */
export function updateExamCard(data) {
  return post('/api/exam/exam/updateExamCard', data)
}
