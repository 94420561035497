import { get, post } from '@/utils/request'

/**
 * 通用请求类
 * @param url
 * @param data
 * @returns {Promise}
 */
export function postData(url, data) {
  return post(url, data)
}

export function getData(url, data) {
  return get(url, data)
}
