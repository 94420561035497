import { post } from '@/utils/request'

export function bindMobile(data) {
  return post('/api/sys/user/bind/bind-mobile', data)
}

export function unBindMobile(data) {
  return post('/api/sys/user/bind/unbind-mobile', data)
}

export function findBindInfo(data) {
  return post('/api/sys/user/bind/bind-info', data)
}

export function directBind(data) {
  return post('/api/sys/user/bind/direct-bind', data)
}

export function directUnBind(data) {
  return post('/api/sys/user/bind/direct-unbind', data)
}

export function bindList(data) {
  return post('/api/sys/user/bind/bind-list', data)
}

export function unbind(data) {
  return post('/api/sys/user/bind/unbind', data)
}

// 管理员解绑
export function unbindById(id) {
  return post('/api/sys/user/bind/unbind-by-id', { id: id })
}
