<template>
  <div v-if="checkData && checkData.id">
    <el-alert v-if="disabled" :title="checkMsg" type="error" style="margin-bottom: 20px" />
    <div>
      <div v-if="checkData.isBuy">
        <el-descriptions :column="1" :label-style="{ width: '100px' }" title="考试信息" border>
          <el-descriptions-item label="考试名称">
            {{ checkData.title }}
          </el-descriptions-item>
          <el-descriptions-item label="考试总分">
            {{ checkData.totalScore }}
          </el-descriptions-item>
          <el-descriptions-item label="及格分数"> {{ checkData.qualifyScore }}分 </el-descriptions-item>
          <el-descriptions-item label="考试时长"> {{ checkData.totalTime }} 分钟 </el-descriptions-item>
          <el-descriptions-item label="考试类型">
            {{ checkData.examType_dictText }}
          </el-descriptions-item>
          <el-descriptions-item v-if="checkData.content" label="注意事项">
            <div v-html="$options.filters.formatRichText(checkData.content)">考前注意事项</div>
          </el-descriptions-item>
          <el-descriptions-item v-if="checkData.openType === 9" label="输入密码">
            <el-input v-model="postForm.password" show-password size="mini" type="password" style="width: 200px" />
          </el-descriptions-item>
          <el-descriptions-item v-if="checkData.camOn && checkData.examType === '1'" label="设备调试">
            <el-checkbox v-model="deviceDebug"> 我已完成设备调试 </el-checkbox>
            <el-link style="margin-left: 20px" type="primary" icon="el-icon-camera" @click="showDeviceTest">
              调试摄像头
            </el-link>
          </el-descriptions-item>
          <el-descriptions-item v-if="checkData.faceOn" label="设备调试">
            <el-checkbox v-model="deviceDebug"> 我已完成设备调试</el-checkbox>
            <el-link style="margin-left: 20px" type="primary" icon="el-icon-camera" @click="showDeviceTest">
              调试主摄像头&副摄像头&屏幕录制
            </el-link>
          </el-descriptions-item>
        </el-descriptions>
        <div class="settle-box">
          <div>1、多次考试取最后一次作为最终成绩；</div>
          <div>2、如有积分和证书将在考试结束后统一发放；</div>
        </div>
        <div style="margin-top: 20px">
          <el-button @click="goBack"> 返回 </el-button>
          <el-button
            :loading="loading"
            :disabled="buttonNext()"
            type="primary"
            icon="el-icon-s-promotion"
            @click="handleCreate"
          >
            开始考试
          </el-button>
          <el-button
            v-if="checkData.faceOn || checkData.openType === 9"
            type="danger"
            icon="el-icon-help"
            @click="handleApply"
          >
            异常申请
          </el-button>
        </div>
      </div>
      <div v-else class="pay-box">
        <div>此考试需要购买，请付费后再进入!</div>
        <div class="price">￥{{ checkData.price }}</div>
        <div>
          <el-button type="primary" @click="handleBuy"> 购买此考试 </el-button>
        </div>
      </div>
    </div>
    <!-- 支付弹窗 -->
    <!-- 人脸识别 -->
    <face-check-dialog
      v-if="!disabled"
      :visible.sync="faceDialog"
      :params="{ examId: postForm.examId }"
      url="/api/paper/capture/face-check"
      @success="faceBack"
    />
    <!-- 异常申请 -->
    <exam-apply-dialog v-if="!disabled" :exam-id="postForm.examId" :visible.sync="applyVisible" />
    <!--普通调试-->
    <simple-device-test v-if="checkData.examType === '1'" :visible.sync="deviceVisible" />
    <!-- 音视频调试 -->
  </div>
</template>
<script>
import { checkProcess } from '@/api/paper/exam'
import { checkInfo } from '@/api/exam/exam'
import { createPaper } from '@/api/paper/exam'

export default {
  data() {
    return {
      loading: false,
      payVisible: false,
      courseId: '',
      faceDialog: false,
      checkData: {
        answerDevice: 0,
        overTime: false,
        examCount: 0,
        lateMax: 0,
      },
      postForm: {
        examId: null,
        faceToken: null,
      },

      deviceDebug: false,

      rules: {
        password: [{ required: true, message: '考试密码不能为空！' }],
      },

      faceInfo: {},

      disabled: false,
      checkMsg: '',
      applyVisible: false,

      // 设备调试
      deviceVisible: false,
    }
  },
  created() {
    const id = this.$route.params.id
    this.courseId = this.$route.query.courseId
    this.activityId = this.$route.query.activityId

    if (id) {
      this.postForm.examId = id
      this.fetchData()
    }
  },

  methods: {
    buttonNext() {
      // 人脸认证未通过
      if (this.checkData.faceOn && (this.postForm.faceToken === null || this.postForm.faceToken === '')) {
        return true
      }

      // 开启了视频但未勾选调试通过
      if (this.checkData.examType === '1' && this.checkData.camOn && !this.deviceDebug) {
        return true
      }

      // 三路监控
      if (this.checkData.examType === '2' && this.deviceDebug) {
        return true
      }

      if (this.checkData.answerDevice > 1) {
        return true
      }
      return false
    },

    // 判断是否无法继续
    checkState() {
      // 不允许电脑考试
      if (this.checkData.answerDevice > 1) {
        this.checkMsg = '当前考试不支持使用电脑端答题，如有疑问请联系管理员！'
        this.disabled = true
        return
      }

      // 超过考试时间了
      if (this.checkData.overTime) {
        this.checkMsg = '考试未开始或已结束!'
        this.disabled = true
        return
      }

      // 迟到了
      if (this.checkData.late) {
        this.checkMsg = `您已经迟到超过${this.checkData.lateMax}分钟，不允许进入考试！`
        this.disabled = true
        return
      }

      // 没有考试机会了
      if (this.checkData.chance > 0 && this.checkData.examCount >= this.checkData.chance) {
        this.checkMsg = `考试次数超限，总共有${this.checkData.chance}次考试机会！`
        this.disabled = true
        return
      }

      this.disabled = false
    },

    // 查询考试详情
    fetchData() {
      checkInfo(this.postForm.examId).then((res) => {
        // 考试详情
        this.checkData = res.data
        this.faceDialog = this.checkData.faceOn
        this.checkState()
      })
    },

    // 创建试卷
    handleCreate() {
      this.loading = true

      // 追加课程
      this.postForm.courseId = this.courseId
      this.postForm.activityId = this.activityId
      createPaper(this.postForm)
        .then((res) => {
          if (res.code === 0) {
            this.loading = false
            this.$router.push({ name: 'StartExam', params: { id: res.data.id } })
          }
        })
        .catch(this.checkBack)
    },

    faceBack(token) {
      this.postForm.faceToken = token
    },

    goBack() {
      this.$router.push({ name: 'ExamOnline' })
    },

    // 回到考试
    checkBack(err) {
      this.loading = false
      // 有正在进行的考试
      if (err !== undefined && err.message !== undefined && err.message === '20010002') {
        // 删除
        this.$confirm('您有正在进行的考试，点击确定按钮继续考试！', '提示信息', {
          confirmButtonText: '确定',
          showCancelButton: false,
          showClose: false,
          type: 'warning',
        }).then(() => {
          checkProcess().then((res) => {
            if (res.data && res.data.id) {
              this.$router.push({ name: 'StartExam', params: { id: res.data.id } })
            }
          })
        })
      }
    },

    handleBuy() {
      this.payVisible = true
    },
    handleApply() {
      this.applyVisible = true
    },
    showDeviceTest() {
      this.deviceVisible = true
    },
  },
}
</script>
<style scoped>
/deep/ .el-descriptions-item__label {
  width: 100px;
}

.pay-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 50px;
  align-items: center;
}

.pay-box .price {
  color: #ff0000;
  font-size: 28px;
  font-weight: 700;
}

.settle-box {
  border: #ff3333 2px dashed;
  margin-top: 20px;
  padding: 10px;
  line-height: 22px;
  font-size: 14px;
  color: #555;
  font-weight: 500;
}
</style>
