<template>
  <div>
    <el-row :gutter="20" class="guide">
      <el-col :span="24">
        <div style="display: flex; align-items: flex-end">
          <h3 style="flex-grow: 1">统计总览</h3>
          <div style="font-size: 12px; padding-bottom: 5px; color: #666">系统版本：{{ version }}</div>
        </div>
      </el-col>

      <el-col :span="24">
        <el-card class="data-out">
          <div style="display: flex; justify-content: space-around">
            <div v-if="siteData.props.moduleExam && stat.examCount" class="data-box">
              <div class="data-title">考试场数</div>
              <div class="data-content">
                <router-link :to="{ name: 'ExamList' }">
                  <count-to :start-val="0" :end-val="stat.examCount" :duration="1500" />
                </router-link>
              </div>
            </div>

            <!--            <div class="data-box">-->
            <!--              <div class="data-title">试卷总数</div>-->
            <!--              <div class="data-content">-->
            <!--                <router-link :to="{name: 'ExamTmpl'}">-->
            <!--                  <count-to :start-val="0" :end-val="stat.tmplCount" :duration="2000" />-->
            <!--                </router-link>-->
            <!--              </div>-->
            <!--            </div>-->

            <div v-if="siteData.props.moduleExam && stat.repoCount" class="data-box">
              <div class="data-title">题库数量</div>
              <div class="data-content">
                <router-link :to="{ name: 'RepoList' }">
                  <count-to :start-val="0" :end-val="stat.repoCount" :duration="2000" />
                </router-link>
              </div>
            </div>

            <div v-if="siteData.props.moduleExam && stat.quCount" class="data-box">
              <div class="data-title">试题总数</div>
              <div class="data-content">
                <router-link :to="{ name: 'QuList' }">
                  <count-to :start-val="0" :end-val="stat.quCount" :duration="2000" />
                </router-link>
              </div>
            </div>

            <div v-if="siteData.props.moduleCourse && stat.courseCount" class="data-box">
              <div class="data-title">课程总数</div>
              <div class="data-content">
                <router-link :to="{ name: 'CourseList' }">
                  <count-to :start-val="0" :end-val="stat.courseCount" :duration="2000" />
                </router-link>
              </div>
            </div>

            <div v-if="siteData.props.moduleCourse && stat.fileCount" class="data-box">
              <div class="data-title">课件总数</div>
              <div class="data-content">
                <router-link :to="{ name: 'CourseFileList' }">
                  <count-to :start-val="0" :end-val="stat.fileCount" :duration="2000" />
                </router-link>
              </div>
            </div>

            <div v-if="siteData.props.moduleBattle && stat.battleCount" class="data-box">
              <div class="data-title">竞赛数量</div>
              <div class="data-content">
                <router-link :to="{ name: 'BattleList' }">
                  <count-to :start-val="0" :end-val="stat.battleCount" :duration="2000" />
                </router-link>
              </div>
            </div>

            <div v-if="siteData.props.moduleCert && stat.grantCount" class="data-box">
              <div class="data-title">发证数量</div>
              <div class="data-content">
                <router-link :to="{ name: 'CertGrantList' }">
                  <count-to :start-val="0" :end-val="stat.grantCount" :duration="2000" />
                </router-link>
              </div>
            </div>

            <div v-if="stat.userCount" class="data-box">
              <div class="data-title">用户总数</div>
              <div class="data-content">
                <router-link :to="{ name: 'SysUser' }">
                  <count-to :start-val="0" :end-val="stat.userCount" :duration="2000" />
                </router-link>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import permission from '@/directive/permission'
import CountTo from 'vue-count-to'
import config from '../../../../../package.json'
import { postData } from '@/api/common'
import { mapGetters } from 'vuex'
export default {
  name: 'CardStatNums',
  components: { CountTo },
  directives: { permission },
  data() {
    return {
      version: config.version,
      stat: {
        examCount: 0,
        tmplCount: 0,
        battleCount: 0,
        courseCount: 0,
        fileCount: 0,
        repoCount: 0,
        quCount: 0,
        userCount: 0,
        grantCount: 0,
      },
    }
  },
  computed: {
    ...mapGetters(['siteData']),
  },
  created() {
    this.fetchData()
  },
  methods: {
    // 查找数据
    fetchData() {
      postData('/api/stat/total/num/all', this.listQuery).then((res) => {
        this.stat = res.data
      })
    },
  },
}
</script>

<style scoped>
.data-out {
  background: #0a84ff url('~@/assets/images/dash-ring.png') no-repeat;
  background-size: 100%;
}

.data-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.data-title {
  font-size: 16px;
  font-weight: 700;
  color: #afd5fa;
  text-align: center;
}

.data-content {
  font-size: 26px;
  font-weight: 700;
  color: #fdf7d6;
  margin-top: 10px;
  text-align: center;
}

.data-content a {
  color: #fdf7d6;
  font-weight: 700;
}

.data-content a:hover {
  color: #ff8000;
}
</style>
