<template>
  <el-select
    v-model="currentValue"
    :remote-method="fetchData"
    multiple
    filterable
    size="small"
    remote
    clearable
    placeholder="搜索或选择群组"
    class="filter-item"
    style="width: 100%"
    @change="handlerChange"
    @clear="fetchData('')"
  >
    <el-option v-for="item in dataList" :key="item.id" :label="item.groupName" :value="item.id" />
  </el-select>
</template>

<script>
import { fetchPaging } from '@/api/sys/group/group'

export default {
  name: 'GroupSelect',
  props: {
    value: Array,
  },
  data() {
    return {
      // 下拉选项值
      dataList: [],
      currentValue: [],
    }
  },

  watch: {
    // 检测查询变化
    value: {
      handler() {
        this.currentValue = this.value
      },
    },
  },
  created() {
    this.currentValue = this.value
    this.fetchData('')
  },
  methods: {
    fetchData(e) {
      const params = { current: 1, size: 100, params: { title: e } }
      fetchPaging(params).then((response) => {
        this.dataList = response.data.records
      })
    },
    handlerChange(e) {
      this.$emit('change', e)
      this.$emit('input', e)
    },
  },
}
</script>
