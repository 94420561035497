import { post } from '@/utils/request'

/**
 * 加入我的考试
 * @param id
 * @returns {Promise}
 */
export function joinExam(id) {
  return post('/api/client/exam/join', { id: id })
}
