<template>
  <div>
    <el-dialog title="考生列表" :visible.sync="show1" width="1000px" @close="close">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="realName" label="姓名" width="180"> </el-table-column>

        <el-table-column prop="face" label="人脸识别" width="180">
          <template v-slot="scope">
            <div>
              <el-image
                v-if="scope.row.face"
                style="width: 50px; height: 50px"
                :src="scope.row.face"
                :preview-src-list="[scope.row.face]"
              >
              </el-image>
              <el-image class="img" v-else>
                <div slot="error" class="image-slot">暂无</div>
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="faceState" label="状态">
          <template v-slot="scope">
            <div>
              {{ scope.row.faceState ? '已验证' : '未验证' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作">
          <template v-slot="scope">
            <el-link type="primary" @click="showFace(scope.row)">人脸识别</el-link>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <face-check-dialog
      v-if="show2"
      :visible.sync="show2"
      :params="{ examId: examId, id: nowItem.userId }"
      url="/api/exam/watch/face-check"
      @success="faceBack"
    />
  </div>
</template>

<script>
import { pageUserByIds } from '@/api/exam/watch'
export default {
  name: 'WatchFacial',
  props: {
    show: Boolean,
    examId: String,
  },
  data() {
    return {
      show1: this.show,
      show2: false,
      nowItem: null,
      tableData: [],
      formData: {
        current: 1,
        size: 10,
        params: {
          examId: '',
        },
      },
    }
  },
  methods: {
    showFace(obj) {
      this.show2 = true
      this.nowItem = obj
    },
    getUsers() {
      pageUserByIds(this.formData).then((res) => {
        this.tableData = res.data.records
      })
    },
    close() {
      this.$emit('closed', false)
    },
  },
  created() {
    this.formData.params.examId = this.examId
    this.getUsers()
  },
}
</script>

<style lang="scss" scoped>
.img {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
}
</style>
