<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :show-close="false"
    title="考试申请"
    width="540px"
  >
    <el-form ref="postForm" :model="postForm" :rules="rules" label-position="left" label-width="100px">
      <el-form-item label="申请理由" prop="applyMsg">
        <el-input
          v-model="postForm.applyMsg"
          type="textarea"
          rows="5"
          placeholder="请详细说明情况，描述越详细越容易通过申请哦！"
        />
      </el-form-item>
    </el-form>

    <div style="font-size: 12px; color: #ff0000; line-height: 22px">
      温馨提示：用户摄像头异常或考试密码丢失的情况，管理端审核通过后，当次考试无需人脸识别和密码即可进入考试。
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button type="danger" @click="handleClose">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="handleApply">确 认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { saveApply } from '@/api/exam/apply'

export default {
  name: 'ExamApplyDialog',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    examId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialogVisible: false,
      postForm: {
        applyMsg: '',
      },
      loading: false,
      rules: {
        applyMsg: [{ required: true, message: '申请理由不能为空！' }],
      },
    }
  },

  watch: {
    // 检测查询变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      },
      deep: true,
    },
  },

  created() {
    this.dialogVisible = this.visible
  },

  methods: {
    // 成功回调
    handleApply() {
      // 考试ID
      this.postForm.examId = this.examId

      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }

        this.loading = true

        saveApply(this.postForm)
          .then(() => {
            this.$emit('update:visible', false)
            this.$message.success('审核已提交，管理员审核通过后可以直接开始考试！')
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      })
    },
    handleClose() {
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style scoped>
.face-box {
  position: relative;
  width: 320px;
  height: 300px;
}
</style>
