var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "state-bar" },
        _vm._l(_vm.stateList, function (item) {
          return _c(
            "el-tag",
            {
              key: item.value,
              attrs: {
                type:
                  _vm.listQuery.params.flag === item.value
                    ? "danger"
                    : "primary",
                effect: "plain",
              },
              on: {
                click: function ($event) {
                  return _vm.tabClick(item.value)
                },
              },
            },
            [_vm._v(_vm._s(item.title))]
          )
        }),
        1
      ),
      _c(
        "data-grid",
        {
          ref: "dataGrid",
          attrs: { options: _vm.options, "list-query": _vm.listQuery },
          scopedSlots: _vm._u([
            {
              key: "data-item",
              fn: function (ref) {
                var data = ref.data
                return [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c(
                      "div",
                      { staticStyle: { "flex-grow": "1" } },
                      [
                        _c("exam-state-flag", {
                          attrs: { flag: data.stateFlag },
                        }),
                        _c("detail-link", {
                          attrs: {
                            id: data.id,
                            title: data.title,
                            to: "CheckExam",
                          },
                        }),
                        data.price > 0
                          ? _c(
                              "el-tag",
                              { attrs: { type: "danger", size: "mini" } },
                              [_vm._v("￥" + _vm._s(data.price))]
                            )
                          : _c(
                              "el-tag",
                              { attrs: { type: "success", size: "mini" } },
                              [_vm._v("免费")]
                            ),
                        _c("div", { staticClass: "flex-bt" }, [
                          _c("div", { staticStyle: { "flex-grow": "1" } }, [
                            _c("div", { staticClass: "info-item" }, [
                              _c("span", { staticClass: "title" }, [
                                _vm._v("考试时间："),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(data.startTime) +
                                  " ~ " +
                                  _vm._s(data.endTime) +
                                  " "
                              ),
                            ]),
                            _c("div", { staticClass: "info-item" }, [
                              _c("span", { staticClass: "title" }, [
                                _vm._v("考试时长："),
                              ]),
                              _vm._v(_vm._s(data.totalTime) + " 分钟"),
                            ]),
                            _c("div", { staticClass: "info-item" }, [
                              _c("span", { staticClass: "title" }, [
                                _vm._v("试卷总分："),
                              ]),
                              _vm._v(_vm._s(data.totalScore) + " 分"),
                            ]),
                            _c("div", { staticClass: "info-item" }, [
                              _c("span", { staticClass: "title" }, [
                                _vm._v("及格分数："),
                              ]),
                              _vm._v(_vm._s(data.qualifyScore) + " 分"),
                            ]),
                          ]),
                          _c("div", [
                            data.stateFlag !== "PUBLISHED"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          type: "primary",
                                          icon: "el-icon-document",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toPaperList(data.id)
                                          },
                                        },
                                      },
                                      [_vm._v("考试记录")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      disabled: !data.showBook,
                                      type: "danger",
                                      icon: "el-icon-collection",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toExamBook(data.id)
                                      },
                                    },
                                  },
                                  [_vm._v("考试错题")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  data.tryCount > 0
                    ? _c("div", { staticClass: "record-box" }, [
                        _c("div", [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("我的得分："),
                          ]),
                          _c("span", { staticClass: "info" }, [
                            data.stateFlag !== "PUBLISHED"
                              ? _c("span", [
                                  data.resultType === 1
                                    ? _c("span", [_vm._v("不显示")])
                                    : _c("span", [
                                        _vm._v(
                                          " " + _vm._s(data.userScore) + " "
                                        ),
                                      ]),
                                ])
                              : _c("span", [
                                  _vm._v(" " + _vm._s(data.userScore) + " "),
                                ]),
                          ]),
                        ]),
                        _c("div", [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("通过情况："),
                          ]),
                          _c("span", { staticClass: "info" }, [
                            data.stateFlag !== "PUBLISHED"
                              ? _c("span", [
                                  data.stateFlag === "FINISHED"
                                    ? _c("span", [
                                        data.resultType === 1
                                          ? _c("span", [_vm._v("不显示")])
                                          : _c("span", [
                                              data.passed
                                                ? _c(
                                                    "span",
                                                    { staticClass: "success" },
                                                    [_vm._v("通过")]
                                                  )
                                                : _c(
                                                    "span",
                                                    { staticClass: "danger" },
                                                    [_vm._v("未通过")]
                                                  ),
                                            ]),
                                      ])
                                    : _c("span", { staticClass: "warning" }, [
                                        _vm._v(" 待出分 "),
                                      ]),
                                ])
                              : _c("span", [
                                  data.passed
                                    ? _c("span", { staticClass: "success" }, [
                                        _vm._v("通过"),
                                      ])
                                    : _c("span", { staticClass: "danger" }, [
                                        _vm._v("未通过"),
                                      ]),
                                ]),
                          ]),
                        ]),
                        _c("div", [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("考试机会："),
                          ]),
                          data.chance === 0
                            ? _c("span", { staticClass: "info" }, [
                                _vm._v("不限"),
                              ])
                            : _c(
                                "span",
                                {
                                  staticClass: "info",
                                  class: {
                                    "no-chance": data.tryCount >= data.chance,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(data.tryCount) +
                                      "/" +
                                      _vm._s(data.chance) +
                                      "次 "
                                  ),
                                ]
                              ),
                        ]),
                      ])
                    : _vm._e(),
                  data.tryCount == 0 && data.stateFlag == "PUBLISHED"
                    ? _c("div", { staticClass: "record-box" }, [
                        _c("span", { staticClass: "danger" }, [_vm._v("缺考")]),
                      ])
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "filter-content" },
            [
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: {
                  placeholder: "搜索考试名称",
                  size: "small",
                  "prefix-icon": "el-icon-search",
                },
                model: {
                  value: _vm.listQuery.params.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery.params, "title", $$v)
                  },
                  expression: "listQuery.params.title",
                },
              }),
              _c("el-date-picker", {
                staticClass: "filter-item",
                attrs: {
                  size: "small",
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.listQuery.params.dateRange,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery.params, "dateRange", $$v)
                  },
                  expression: "listQuery.params.dateRange",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }