import Vue from 'vue'
import Router from 'vue-router'
import LoginLayout from '@/views/login/layout/LoginLayout'
Vue.use(Router)
import Layout from '@/layout'

// 静态通用路由
export const constantRoutes = [
  {
    path: '/',
    component: () => import('@/views/index'),
    hidden: true,
  },
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import('@/views/redirect/index'),
      },
    ],
  },
  {
    path: '/pages/login',
    component: LoginLayout,
    redirect: '/pages/login/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/login/index'),
        meta: {
          title: '用户登录',
        },
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/login/register'),
        meta: {
          title: '学员注册',
        },
      },
      {
        path: 'forgot',
        name: 'Forgot',
        component: () => import('@/views/login/forgot'),
        meta: {
          title: '忘记密码',
        },
      },
    ],
  },

  {
    path: '/pages/login/sync',
    name: 'LoginSync',
    component: () => import('@/views/login/sync'),
    hidden: true,
    meta: { title: '登录成功', noCache: true },
  },
  {
    path: '/profile',
    component: Layout,
    redirect: '/profile/index',
    hidden: true,
    children: [
      {
        path: 'index',
        component: () => import('@/views/profile/index'),
        name: 'Profile',
        meta: { title: '个人资料', icon: 'user', noCache: true },
      },
    ],
  },
]

// 导入目录下的子路由
const subRouters = require.context('./modules', true, /\.js$/)
subRouters.keys().reduce((modules, modulePath) => {
  const value = subRouters(modulePath)
  constantRoutes.push(value.default)
}, {})

const createRouter = () =>
  new Router({
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  })

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
