<template>
  <div>
    <div class="state-bar">
      <el-tag
        v-for="item in stateList"
        :key="item.value"
        :type="listQuery.params.flag === item.value ? 'danger' : 'primary'"
        effect="plain"
        @click="tabClick(item.value)"
        >{{ item.title }}</el-tag
      >
    </div>

    <data-grid ref="dataGrid" :options="options" :list-query="listQuery">
      <template slot="filter-content">
        <el-input
          v-model="listQuery.params.title"
          class="filter-item"
          placeholder="搜索考试名称"
          size="small"
          prefix-icon="el-icon-search"
          style="width: 200px"
        />

        <el-date-picker
          v-model="listQuery.params.dateRange"
          class="filter-item"
          size="small"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </template>
      <template v-slot:data-item="{ data }">
        <div style="display: flex">
          <div style="flex-grow: 1">
            <exam-state-flag :flag="data.stateFlag" />
            <detail-link :id="data.id" :title="data.title" to="CheckExam" />
            <el-tag v-if="data.price > 0" type="danger" size="mini">￥{{ data.price }}</el-tag>
            <el-tag v-else type="success" size="mini">免费</el-tag>
            <div class="flex-bt">
              <div style="flex-grow: 1">
                <div class="info-item">
                  <span class="title">考试时间：</span>
                  {{ data.startTime }} ~ {{ data.endTime }}
                </div>
                <div class="info-item"><span class="title">考试时长：</span>{{ data.totalTime }} 分钟</div>
                <div class="info-item"><span class="title">试卷总分：</span>{{ data.totalScore }} 分</div>
                <div class="info-item"><span class="title">及格分数：</span>{{ data.qualifyScore }} 分</div>
              </div>
              <div>
                <div v-if="data.stateFlag !== 'PUBLISHED'">
                  <el-link type="primary" icon="el-icon-document" @click="toPaperList(data.id)">考试记录</el-link>
                </div>

                <div>
                  <el-link
                    :disabled="!data.showBook"
                    type="danger"
                    icon="el-icon-collection"
                    @click="toExamBook(data.id)"
                    >考试错题</el-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="data.tryCount > 0" class="record-box">
          <div>
            <span class="title">我的得分：</span>
            <span class="info">
              <span v-if="data.stateFlag !== 'PUBLISHED'">
                <span v-if="data.resultType === 1">不显示</span>
                <span v-else>
                  {{ data.userScore }}
                </span>
              </span>
              <span v-else>
                {{ data.userScore }}
              </span>
            </span>
          </div>

          <div>
            <span class="title">通过情况：</span>
            <span class="info">
              <span v-if="data.stateFlag !== 'PUBLISHED'">
                <span v-if="data.stateFlag === 'FINISHED'">
                  <span v-if="data.resultType === 1">不显示</span>
                  <span v-else>
                    <span v-if="data.passed" class="success">通过</span>
                    <span v-else class="danger">未通过</span>
                  </span>
                </span>
                <span v-else class="warning"> 待出分 </span>
              </span>
              <span v-else>
                <span v-if="data.passed" class="success">通过</span>
                <span v-else class="danger">未通过</span>
              </span>
            </span>
          </div>

          <div>
            <span class="title">考试机会：</span>
            <span v-if="data.chance === 0" class="info">不限</span>
            <span v-else :class="{ 'no-chance': data.tryCount >= data.chance }" class="info">
              {{ data.tryCount }}/{{ data.chance }}次
            </span>
          </div>
        </div>

        <div class="record-box" v-if="data.tryCount == 0 && data.stateFlag == 'PUBLISHED'">
          <span class="danger">缺考</span>
        </div>
      </template>
    </data-grid>
  </div>
</template>

<script>
import ExamStateFlag from '@/views/web/exam/components/ExamStateFlag/index.vue'

export default {
  name: 'MyExamPage',
  components: { ExamStateFlag },
  data() {
    return {
      stateList: [
        { value: 'ALL', title: '全部考试' },
        { value: 'READY_START', title: '未开始' },
        { value: 'PROCESSING', title: '考试中' },
        { value: 'WAIT_REVIEW', title: '待阅卷' },
        { value: 'IN_REVIEW', title: '阅卷中' },
        { value: 'FINISHED', title: '已出分' },
        { value: 'PUBLISHED', title: '成绩发布' },
      ],

      listQuery: {
        current: 1,
        size: 10,
        params: {
          flag: 'ALL',
        },
      },

      options: {
        // 列表请求URL
        listUrl: '/api/client/exam/mine-paging',
      },
    }
  },

  created() {},
  methods: {
    // 刷新页面
    reload() {
      this.$refs.dataGrid.getList()
    },

    tabClick(val) {
      this.listQuery.params.flag = val
    },

    toPaperList(id) {
      this.$router.push({ name: 'WebPaperList', query: { examId: id } })
    },

    toExamBook(id) {
      this.$router.push({ name: 'WrongBook', query: { refId: id, refType: 'exam' } })
    },
  },
}
</script>

<style lang="scss" scoped>
.state-bar {
  padding-bottom: 10px;
  border-bottom: #f1f1f1 1px solid;
  margin-bottom: 15px;
}

.state-bar > .el-tag {
  margin-right: 10px;
  cursor: pointer;
}

.no-chance {
  color: #ff3333 !important;
}
</style>
