<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      // 缓存
      psKey: 'exam-process-cache',
    }
  },
  watch: {
    $route(to, from) {
      console.log('router form %s to %s', from.name, to.name)

      // 强制刷新页面
      if (to.name !== 'StartExam' && to.name !== 'ExamScreen') {
        this.clearExamCache()
      }
    },
  },
  mounted() {
    console.log('object', process.env.VUE_APP_BASE_API)
  },
  methods: {
    // 彻底清理考试缓存
    clearExamCache() {
      const cache = localStorage.getItem(this.psKey)
      if (cache && cache === 'yes') {
        localStorage.removeItem(this.psKey)
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      }
    },
  },
}
</script>
