<template>
  <qu-item-exam v-model="quData" @fill="handleSave" @action="handleAction" />
</template>

<script>
import { quDetail, fillAnswer } from '@/api/paper/exam'
import { scrollToDom } from '@/utils/scroll-to'

export default {
  name: 'ExamStepMode',
  props: {
    paper: {
      type: Object,
      default: function () {
        return {
          leftSeconds: 99999,
        }
      },
    },
  },
  data() {
    return {
      pageLoading: false,
      // 当前试题内容
      quData: {
        answerList: [],
      },
      // 试卷信息
      paperData: {
        leftSeconds: 99999,
      },
    }
  },

  watch: {
    // 检测查询变化
    paper: {
      handler() {
        this.fillPaper()
      },
      deep: true,
    },
  },

  created() {
    this.fillPaper()
  },

  methods: {
    // 填充试卷信息
    fillPaper() {
      if (this.paper && this.paper.id) {
        this.paperData = this.paper
      }
    },

    // 题目变化保存答案
    handleSave(data) {
      // 提交保存
      fillAnswer(data).then((res) => {
        this.$emit('saved', res.data)
      })
    },

    // 试卷详情
    fetchQuData(quId, subId) {
      if (quId === this.quData.quId && subId) {
        this.focusSub(subId)
        return
      }

      // 查找下个详情
      const params = { paperId: this.paper.id, quId: quId }
      quDetail(params)
        .then((res) => {
          this.quData = res.data

          // 定位到子题目
          if (subId) {
            this.$nextTick(() => {
              this.focusSub(subId)
            })
          } else {
            // 还原滚动位置
            const dom = document.querySelector('#exam-body')
            scrollToDom(dom, 20, 600, function () {})
          }
        })
        .catch(this.errorBack)
    },

    // 试题异常
    errorBack(err) {
      // 试题被删除的情况
      if (err !== undefined && err.message !== undefined && err.message === '20010001') {
        // 回到首页
        setTimeout(() => {
          this.$router.push('/pages/exam?break')
        }, 1500)
      }
    },

    // 指定到子题目
    focusSub(subId) {
      const dom = document.querySelector('#exam-body')
      const anchor = document.getElementById(`qu-${subId}`)
      const to = anchor.offsetTop - 20
      // 滑动到指定位置
      scrollToDom(dom, to, 600, function () {})
    },
    // 操作变化
    handleAction() {
      this.$emit('action')
    },
  },
}
</script>
