// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/dash-ring.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.data-out[data-v-c8ce0c30] {\r\n  background: #0a84ff url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\r\n  background-size: 100%;\n}\n.data-box[data-v-c8ce0c30] {\r\n  display: -webkit-box;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -webkit-box-align: center;\r\n      -ms-flex-align: center;\r\n          align-items: center;\r\n  -webkit-box-pack: center;\r\n      -ms-flex-pack: center;\r\n          justify-content: center;\r\n  -webkit-box-orient: vertical;\r\n  -webkit-box-direction: normal;\r\n      -ms-flex-direction: column;\r\n          flex-direction: column;\n}\n.data-title[data-v-c8ce0c30] {\r\n  font-size: 16px;\r\n  font-weight: 700;\r\n  color: #afd5fa;\r\n  text-align: center;\n}\n.data-content[data-v-c8ce0c30] {\r\n  font-size: 26px;\r\n  font-weight: 700;\r\n  color: #fdf7d6;\r\n  margin-top: 10px;\r\n  text-align: center;\n}\n.data-content a[data-v-c8ce0c30] {\r\n  color: #fdf7d6;\r\n  font-weight: 700;\n}\n.data-content a[data-v-c8ce0c30]:hover {\r\n  color: #ff8000;\n}\r\n", ""]);
// Exports
module.exports = exports;
