<template>
  <div class="result-box">
    <div class="ps-box">
      <div>
        <span class="t1">已答正确率：</span>
        <span class="t2">{{ answerRate }}</span>
        <span class="t3">%</span>
      </div>

      <div>
        <span class="t1">总体正确率：</span>
        <span class="t2">{{ allRate }}</span>
        <span class="t3">%</span>
      </div>
    </div>

    <div style="display: flex; flex-wrap: wrap">
      <div style="width: 33%; display: flex; align-items: center">
        <i class="el-icon-success dt-item" style="color: #1aac1a">答对：{{ detail.rightCount }}题</i>
      </div>
      <div style="width: 34%; display: flex; align-items: center">
        <i class="el-icon-info dt-item" style="color: #f0ad4e">已答：{{ detail.answerCount }}题</i>
      </div>

      <div style="width: 33%; display: flex; align-items: center">
        <i class="el-icon-circle-plus dt-item" style="color: #ff3333">总共：{{ detail.totalCount }}题</i>
      </div>
    </div>

    <div style="width: 100%">
      <el-button type="danger" style="width: 200px" @click="handleBack">返回列表</el-button>
    </div>
  </div>
</template>

<script>
import { trainDetail } from '@/api/repo/train'

export default {
  components: {},
  data() {
    return {
      trainId: null,
      detail: {},
      answerRate: 0,
      allRate: 0,
    }
  },

  created() {
    this.trainId = this.$route.params.trainId

    trainDetail(this.trainId).then((res) => {
      this.detail = res.data
      this.calcRate()
    })
  },

  methods: {
    // 计算比例
    calcRate() {
      if (this.detail.rightCount > 0 && this.detail.answerCount > 0) {
        this.answerRate = parseInt((this.detail.rightCount * 100) / this.detail.answerCount)
      }

      if (this.detail.rightCount > 0 && this.detail.totalCount > 0) {
        this.allRate = parseInt((this.detail.rightCount * 100) / this.detail.totalCount)
      }
    },

    handleBack() {
      // 题库错题
      if (this.detail.trainMode === 'repo') {
        this.$router.push({ name: 'WrongBook', query: { refType: 'repo', refId: this.detail.repoId } })
      } else if (this.detail.trainMode === 'exam') {
        this.$router.push({ name: 'WrongBook', query: { refType: 'exam', refId: this.detail.repoId } })
      } else {
        this.$router.push({ name: 'MyRepoView', params: { repoId: this.detail.repoId } })
      }
    },
  },
}
</script>

<style scoped>
.dt-item {
  font-size: 16px;
  width: 130px;
  font-weight: 700;
}

.ps-box {
  display: flex;
  align-items: center;
}

.ps-box div {
  width: 220px;
  color: #0a84ff;
  font-size: 20px;
  font-weight: 700;
}

.ps-box div .t1 {
  color: #333;
  font-weight: 500;
}

.ps-box div .t2 {
  font-size: 36px;
}

.ps-box div .t3 {
  font-size: 20px;
}

.result-box {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 100px;
  min-height: 50vh;
}

/deep/ .main-bg {
  background: transparent !important;
}
</style>
