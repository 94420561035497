<template>
  <div class="result-box">
    <div v-if="data.valid">
      <div class="score">{{ data.userScore }}<span>分</span></div>
      <div>
        {{ data.thanks }}
      </div>
    </div>
    <div v-else>
      <div class="score" style="color: #ff0000">成绩无效</div>

      <div v-if="data.exMsg" style="color: #666; font-size: 14px">
        {{ data.exMsg }}
      </div>
    </div>

    <div v-if="data.reviewState === 0" style="color: #ff8000; font-weight: 700">
      该试卷还有主观题，主观题分数将在阅卷后累计到总成绩，请知晓！
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThanksWithScore',
  props: {
    data: Object,
  },
  data() {
    return {}
  },
  created() {},
  methods: {},
}
</script>

<style scoped>
.result-box {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 50px;
  min-height: 50vh;
}

.result-box .score {
  font-size: 60px;
  font-weight: 700;
  color: #0a84ff;
}

.result-box .score span {
  font-size: 20px;
}

/deep/ .main-bg {
  background: transparent !important;
}
</style>
