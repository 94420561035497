var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tinymce-container", style: { width: _vm.editorWidth } },
    [
      _c("textarea", {
        staticClass: "tinymce-textarea",
        attrs: { id: _vm.tinymceId },
      }),
      _vm._v(" "),
      _c("editor-attach-dialog", {
        attrs: { visible: _vm.attachVisible },
        on: {
          "update:visible": function ($event) {
            _vm.attachVisible = $event
          },
          uploaded: _vm.handleAttr,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }