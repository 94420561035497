<template>
  <span class="time-cc">{{ min }}分钟{{ sec }}秒</span>
</template>

<script>
import { leftTime } from '@/api/paper/paper'

export default {
  name: 'ExamTimer',
  props: {
    paperId: String,
  },

  data() {
    return {
      timerFetch: null,
      timerShow: null,
      init: false,
      leftSeconds: 0,
      min: '00',
      sec: '00',
    }
  },
  watch: {
    paperId: {
      handler() {
        this.fetchLeft()
      },
    },
  },
  created() {
    this.fetchLeft()
  },

  beforeDestroy() {
    this.clearAll()
  },

  methods: {
    // 获取剩余时长
    fetchLeft() {
      this.init = false
      leftTime(this.paperId).then((res) => {
        // 填充剩余时间
        this.initTime(res.data)
      })
    },

    // 重新初始化
    async initTime(seconds) {
      // 清理定时任务
      await this.clearAll()
      // 时间不对，直接返回
      if (seconds <= 0) {
        this.$emit('timeout')
        return
      }
      // 开始执行功能
      this.init = true
      // 填充剩余时间
      this.leftSeconds = seconds
      // 每秒展示
      this.timerShow = setInterval(this.countdown, 1000)
      // 30秒刷新时间
      this.timerFetch = setInterval(this.fetchLeft, 30000)
    },

    // 清除定时器
    async clearAll() {
      if (this.timerShow) {
        clearInterval(this.timerShow)
      }
      if (this.timerFetch) {
        clearInterval(this.timerFetch)
      }
    },

    // 进行倒计时
    async countdown() {
      // 倒计时结束了
      if (this.leftSeconds <= 0 && this.init) {
        await this.clearAll()
        this.$emit('timeout')
        return
      }
      // 时
      const min = parseInt(this.leftSeconds / 60)
      const sec = parseInt(this.leftSeconds % 60)
      this.min = min > 9 ? min : '0' + min
      this.sec = sec > 9 ? sec : '0' + sec
      this.leftSeconds -= 1
    },
  },
}
</script>

<style scoped>
.time-cc {
  color: #ff0000;
  font-weight: 700;
}
</style>
