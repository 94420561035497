<template>
  <div>
    <el-form label-width="100px" label-position="left">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="用户编号">
            <el-input v-model="postForm.id" size="small" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="登录账号">
            <el-input v-model="postForm.userName" size="small" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="姓名">
            <el-input v-model="postForm.realName" :disabled="postForm.realPerson" size="small" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属部门">
            <el-input v-model="postForm.deptCode_dictText" size="small" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="邮箱">
            <el-input v-model="postForm.email" size="small" />
          </el-form-item>
        </el-col>

        <el-col v-if="!siteData.props.faceLogin" :span="12">
          <el-form-item label="真实姓名">
            <el-input v-model="postForm.realName" size="small" />
          </el-form-item>
        </el-col>

        <el-col v-if="!siteData.props.faceLogin" :span="12">
          <el-form-item label="身份证号">
            <el-input v-model="postForm.idCard" size="small" />
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="用户头像">
            <file-upload
              v-model="postForm.avatar"
              :limit="1"
              :compress="5000"
              list-type="picture-card"
              tips="请上传jpg或png格式图片"
              accept=".jpg,.png,.jpeg"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item>
        <el-button :loading="loading" type="primary" @click="updateInfo">更新资料</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { findInfo, updateData } from '@/api/sys/user/user'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      postForm: {},
      bindVisible: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['token', 'siteData']),
  },

  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findInfo(this.token).then((res) => {
        this.postForm = res.data
      })
    },

    updateInfo() {
      this.loading = true

      updateData(this.postForm)
        .then(() => {
          this.loading = false
          this.$notify({
            title: '成功',
            message: '资料修改成功！',
            type: 'success',
            duration: 2000,
          })

          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
        .catch(() => {
          this.loading = false
        })
    },

    showBind() {
      this.bindVisible = true
    },
  },
}
</script>

<style scoped></style>
