import CryptoJS from 'crypto-js'

const AES_KEY = process.env['VUE_APP_AES_KEY']
const AES_IV = process.env['VUE_APP_AES_IV']

const key = CryptoJS.enc.Utf8.parse(AES_KEY)
const iv = CryptoJS.enc.Utf8.parse(AES_IV)

/**
 * 加密方法
 * @param text
 * @returns {string}
 */
export function encode(text) {
  const srcs = CryptoJS.enc.Utf8.parse(text)
  const encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
  return encrypted.ciphertext.toString(CryptoJS.enc.Base64)
}

/**
 * 解密方法
 * @param word
 * @returns {string}
 */
export function decode(text) {
  const encryptedHexStr = CryptoJS.enc.Base64.parse(text)
  const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr)
  const decrypt = CryptoJS.AES.decrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  return decryptedStr.toString()
}
