<template>
  <div style="display: flex; align-items: center; font-size: 10px">
    <el-input-number
      v-model="min"
      :controls="false"
      :min="0"
      :max="9999"
      style="width: 50px"
      size="mini"
    />分<el-input-number :controls="false" v-model="sec" :min="0" :max="59" style="width: 30px" size="mini" />秒
  </div>
</template>

<script>
export default {
  name: 'SecondSet',
  props: {
    value: {
      type: Number,
      default: function () {
        return 0
      },
    },
  },
  data() {
    return {
      min: 0,
      sec: 0,
    }
  },
  watch: {
    // 检测查询变化
    value: {
      handler(val) {
        this.calc(val)
      },
    },
    min: {
      handler() {
        this.rest()
      },
    },
    sec: {
      handler() {
        this.rest()
      },
    },
  },
  created() {
    this.calc(this.value)
  },
  methods: {
    // 计算
    calc() {
      const num = this.value
      this.min = parseInt(num / 60)
      this.sec = num % 60
    },

    // 返回
    rest() {
      const num = this.min * 60 + this.sec
      this.$emit('input', num)
    },
  },
}
</script>

<style scoped>
::v-deep .el-input-number.is-without-controls .el-input__inner {
  padding-left: 0;
  padding-right: 0;
}
</style>
