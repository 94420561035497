<template>
  <div>
    <el-form ref="postForm" :model="postForm" :rules="rules" label-position="left" label-width="100px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="登录账号" prop="userName">
            <el-input v-model="postForm.userName" size="small" :disabled="!!postForm.id" />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="用户角色" prop="roles">
            <role-select v-model="postForm.roles" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属部门" prop="deptCode">
            <el-cascader
              :style="{
                width: '100%',
              }"
              ref="tree"
              filterable
              :show-all-levels="false"
              :collapse-tags="true"
              :props="{ multiple: true, checkStrictly: true }"
              placeholder="选择部门"
              class="select-tree"
              v-model="deptCode"
              :options="options"
              @change="onClickNode"
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="登录密码" prop="password">
            <el-input
              v-model="postForm.password"
              :placeholder="placeholder"
              autocomplete="new-password"
              show-password
              type="password"
              size="small"
            />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item v-if="!postForm.id" label="手机号" prop="mobile">
            <el-input v-model="postForm.mobile" size="small" type="text" />
          </el-form-item>
          <el-form-item v-else label="手机绑定">
            <mobile-binder :user-id="postForm.id" />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="电子邮箱">
            <el-input v-model="postForm.email" size="small" />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="到期时间">
            <el-date-picker
              v-model="postForm.expireTime"
              size="small"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="用户头像">
            <file-upload
              v-model="postForm.avatar"
              :limit="1"
              :compress="3000"
              list-type="picture-card"
              tips="仅用于学员端展示"
              accept=".jpg,.png,.jpeg"
            />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="真实姓名" prop="realName">
            <el-input v-model="postForm.realName" size="small" />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="身份证号" prop="idCard">
            <el-input v-model="postForm.idCard" size="small" />
          </el-form-item>
        </el-col>

        <el-col v-if="siteData.faceLogin" :span="24">
          <el-form-item label="人脸照片">
            <file-upload
              v-model="postForm.face"
              :limit="1"
              :compress="3000"
              list-type="picture-card"
              tips="用于人脸登录，需要保证正脸位于图片中央，图片质量会影响识别率"
              accept=".jpg,.png,.jpeg"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div style="border-top: #eee 1px solid; padding-top: 20px; text-align: right">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="handleSave">确 定</el-button>
    </div>
  </div>
</template>

<script>
import { saveData, fetchDetail } from '@/api/sys/user/user'
import { fetchTree } from '@/api/sys/depart/depart'
import MobileBinder from '@/views/admin/sys/user/components/MobileBinder.vue'
import { checkPass, isMobileIgnoresNull, isMobile } from '@/utils/validate'
import { mapGetters } from 'vuex'

export default {
  name: 'UserUpdateBase',
  components: { MobileBinder },
  props: {
    userId: String,
    departId: String,
  },

  data() {
    var checkCode = (rule, value, callback) => {
      if (this.deptCode.length === 0) {
        return callback(new Error('部门不能为空！'))
      } else {
        return callback()
      }
    }

    return {
      loading: false,
      placeholder: '',
      postForm: {},
      deptCode: [],
      options: [],
      rules: {
        userName: [{ required: true, message: '用户不能为空！' }],
        realName: [{ required: true, message: '姓名不能为空！' }],
        // 密码为动态规则
        password: [],
        deptCode: [{ validator: checkCode, trigger: 'change', required: true }],

        mobile: [{ required: true, validator: isMobile }],
        roles: [{ required: true, message: '必须选择一个角色！' }],

        idCard: [{ required: true, message: '身份证不能为空！' }],
      },
    }
  },

  computed: {
    ...mapGetters(['siteData']),
  },

  watch: {
    userId: {
      handler(val) {
        this.showInfo(val)
      },
    },

    departId: {
      handler(val) {
        if (val) {
          this.postForm.departId = val
        }
      },
    },
    postForm: {
      handler(val) {
        if (val && val.id) {
          // 添加校验
          this.rules.password = [{ validator: checkPass }]
          this.placeholder = '不修改请留空！'
        } else {
          this.rules.password = [{ required: true, message: '密码不能为空！' }, { validator: checkPass }]
          this.placeholder = '请输入初始密码！'
        }
      },
      deep: true,
    },
  },

  async created() {
    await this.getTree()
    this.showInfo(this.userId)
  },

  methods: {
    getTree() {
      fetchTree({ dicCode: this.postForm.deptCode }).then((res) => {
        this.options = res.data
        this.switchTree(this.options)
        // // 检测输入框原有值并显示对应 label
        // if (this.value) {
        //   this.labelModel = this.queryTree(this.data, this.value)
        // }
      })
    },
    onClickNode(val) {
      console.log(this.deptCode, 234)
    },
    //options转为树状层级结构
    switchTree(options) {
      options.forEach((item) => {
        item.label = item.deptName
        item.value = item.deptCode
        if (item.children) {
          this.switchTree(item.children)
        }
      })
    },
    // 清理
    clear() {
      this.postForm = {}
      this.$nextTick(() => {
        if (this.$refs.postForm) {
          this.$refs.postForm.clearValidate()
        }
      })
    },
    findTopParentArray(arr, targetDeptCode) {
      let resultArray = null

      function findArrayByDeptCode(arr, targetDeptCode, path = []) {
        for (let obj of arr) {
          if (obj.deptCode === targetDeptCode) {
            resultArray = [obj, ...path]
          } else if (obj.children) {
            findArrayByDeptCode(obj.children, targetDeptCode, [obj, ...path])
          }
        }
      }

      findArrayByDeptCode(arr, targetDeptCode)

      return resultArray
    },
    // 显示信息用于编辑
    showInfo(id) {
      this.deptCode = []
      // 添加模式
      if (!id) {
        this.clear()
        return
      }
      fetchDetail(id).then((res) => {
        this.postForm = res.data
        this.deptCode = []
        let arr1 = []
        res.data.deptCode.split(',').forEach((item) => {
          let arr = this.findTopParentArray(this.options, item)
          if (arr) {
            arr1.push(arr.map((item) => item.deptCode).reverse())
          }
        })
        this.deptCode = arr1
        this.postForm.password = ''
      })
    },

    async handleSave() {
      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }
        this.loading = true
        this.postForm.deptCode = this.deptCode.map((item) => item[item.length - 1]).join(',')
        saveData(this.postForm)
          .then(() => {
            this.$message({
              type: 'success',
              message: '用户保存成功!',
            })
            this.loading = false
            this.handleCancel()
          })
          .catch(() => {
            this.loading = false
          })
      })
    },

    handleCancel() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.select-tree {
  max-height: 350px;
  /* overflow-y: scroll; */
}
</style>
