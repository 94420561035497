var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "line-height": "35px" } },
    [
      _vm._m(0),
      _c(
        "el-button",
        {
          attrs: { loading: _vm.loading1, size: "mini", round: "" },
          on: { click: _vm.studentLogin },
        },
        [_vm._v("学员账号")]
      ),
      _c(
        "el-button",
        {
          attrs: { loading: _vm.loading2, size: "mini", round: "" },
          on: { click: _vm.adminLogin },
        },
        [_vm._v("管理员账号")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-line" }, [
      _vm._v(" 演示账号 "),
      _c("span", { staticStyle: { "font-size": "12px", color: "#ff0000" } }, [
        _vm._v("（点击直接体验，无需注册）"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }