<template>
  <data-grid ref="dataGrid" :options="options" :list-query="listQuery">
    <template #filter-content>
      <dic-catalog-tree v-model="listQuery.params.catId" dic-code="repo_catalog" width="200" class="filter-item" />

      <el-input
        v-model="listQuery.params.title"
        class="filter-item"
        size="small"
        placeholder="搜索题库名称"
        prefix-icon="el-icon-search"
        style="width: 200px"
      />
    </template>

    <template v-slot:data-item="{ data }">
      <div class="flex-bt">
        <div style="flex-grow: 1">
          <detail-link :params="{ repoId: data.id }" :title="data.title" to="MyRepoView" />
          <span class="tag-box">
            <el-tag v-if="data.price > 0" type="danger" size="mini">￥{{ data.price }}</el-tag>
            <el-tag v-else type="success" size="mini">免费</el-tag>
          </span>

          <div class="info-item">
            <span class="title">题库分类：</span>
            <span>{{ data.catId_dictText }}</span>
          </div>
          <div class="info-item">
            <span class="title">试题数量： </span>
            <span>{{ data.quCount }}题</span>
          </div>
          <div class="info-item">
            <span class="title">训练覆盖： </span>
            <span>{{ data.coverCount }}题（{{ data.coverRate }}%）</span>
          </div>
        </div>
        <div>
          <div>
            <el-link type="danger" icon="el-icon-document-delete" @click="toBook(data.id)"
              >错题记录（{{ data.wrongCount }}）</el-link
            >
          </div>
          <div>
            <el-link type="primary" icon="el-icon-tickets" @click="toTrainRecord(data.id)"
              >训练记录（{{ data.trainCount }}）</el-link
            >
          </div>
        </div>
      </div>

      <div v-if="data.latestTrain" class="record-box" style="margin-top: 10px">
        <div>
          <span class="title">最近训练：</span>
          <span class="info">
            {{ data.latestTrain.trainMode_dictText }}
          </span>
        </div>

        <div>
          <span class="title">正确率：</span>
          <span class="info"> {{ data.latestTrain.percent }}% </span>
        </div>

        <div>
          <span class="title">训练时间：</span>
          <span class="info">
            {{ data.latestTrain.updateTime }}
          </span>
        </div>
      </div>
    </template>
  </data-grid>
</template>

<script>
import DataGrid from '@/components/DataGrid/index.vue'

export default {
  name: 'MyRepoPage',
  components: { DataGrid },
  data() {
    return {
      listQuery: {
        current: 1,
        size: 10,
        params: {
          forTrain: true,
        },
      },

      options: {
        // 列表请求URL
        listUrl: '/api/client/repo/mine-paging',
        lineCount: 1,
      },
    }
  },
  methods: {
    refresh() {
      this.$refs.dataGrid.getList()
    },

    // 进入错题本
    toBook(id) {
      this.$router.push({ name: 'WrongBook', query: { refId: id, refType: 'repo' } })
    },

    // 训练记录
    toTrainRecord(repoId) {
      this.$router.push({ name: 'TrainRecord', query: { repoId: repoId } })
    },
  },
}
</script>
