var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.checkData && _vm.checkData.id
    ? _c(
        "div",
        [
          _vm.disabled
            ? _c("el-alert", {
                staticStyle: { "margin-bottom": "20px" },
                attrs: { title: _vm.checkMsg, type: "error" },
              })
            : _vm._e(),
          _c("div", [
            _vm.checkData.isBuy
              ? _c(
                  "div",
                  [
                    _c(
                      "el-descriptions",
                      {
                        attrs: {
                          column: 1,
                          "label-style": { width: "100px" },
                          title: "考试信息",
                          border: "",
                        },
                      },
                      [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "考试名称" } },
                          [_vm._v(" " + _vm._s(_vm.checkData.title) + " ")]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "考试总分" } },
                          [_vm._v(" " + _vm._s(_vm.checkData.totalScore) + " ")]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "及格分数" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.checkData.qualifyScore) + "分 "
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "考试时长" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.checkData.totalTime) + " 分钟 "
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "考试类型" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.checkData.examType_dictText) +
                                " "
                            ),
                          ]
                        ),
                        _vm.checkData.content
                          ? _c(
                              "el-descriptions-item",
                              { attrs: { label: "注意事项" } },
                              [
                                _c(
                                  "div",
                                  {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$options.filters.formatRichText(
                                          _vm.checkData.content
                                        )
                                      ),
                                    },
                                  },
                                  [_vm._v("考前注意事项")]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.checkData.openType === 9
                          ? _c(
                              "el-descriptions-item",
                              { attrs: { label: "输入密码" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    "show-password": "",
                                    size: "mini",
                                    type: "password",
                                  },
                                  model: {
                                    value: _vm.postForm.password,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.postForm, "password", $$v)
                                    },
                                    expression: "postForm.password",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.checkData.camOn && _vm.checkData.examType === "1"
                          ? _c(
                              "el-descriptions-item",
                              { attrs: { label: "设备调试" } },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value: _vm.deviceDebug,
                                      callback: function ($$v) {
                                        _vm.deviceDebug = $$v
                                      },
                                      expression: "deviceDebug",
                                    },
                                  },
                                  [_vm._v(" 我已完成设备调试 ")]
                                ),
                                _c(
                                  "el-link",
                                  {
                                    staticStyle: { "margin-left": "20px" },
                                    attrs: {
                                      type: "primary",
                                      icon: "el-icon-camera",
                                    },
                                    on: { click: _vm.showDeviceTest },
                                  },
                                  [_vm._v(" 调试摄像头 ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.checkData.faceOn
                          ? _c(
                              "el-descriptions-item",
                              { attrs: { label: "设备调试" } },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value: _vm.deviceDebug,
                                      callback: function ($$v) {
                                        _vm.deviceDebug = $$v
                                      },
                                      expression: "deviceDebug",
                                    },
                                  },
                                  [_vm._v(" 我已完成设备调试")]
                                ),
                                _c(
                                  "el-link",
                                  {
                                    staticStyle: { "margin-left": "20px" },
                                    attrs: {
                                      type: "primary",
                                      icon: "el-icon-camera",
                                    },
                                    on: { click: _vm.showDeviceTest },
                                  },
                                  [_vm._v(" 调试主摄像头&副摄像头&屏幕录制 ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._m(0),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "20px" } },
                      [
                        _c("el-button", { on: { click: _vm.goBack } }, [
                          _vm._v(" 返回 "),
                        ]),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              loading: _vm.loading,
                              disabled: _vm.buttonNext(),
                              type: "primary",
                              icon: "el-icon-s-promotion",
                            },
                            on: { click: _vm.handleCreate },
                          },
                          [_vm._v(" 开始考试 ")]
                        ),
                        _vm.checkData.faceOn || _vm.checkData.openType === 9
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "danger", icon: "el-icon-help" },
                                on: { click: _vm.handleApply },
                              },
                              [_vm._v(" 异常申请 ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c("div", { staticClass: "pay-box" }, [
                  _c("div", [_vm._v("此考试需要购买，请付费后再进入!")]),
                  _c("div", { staticClass: "price" }, [
                    _vm._v("￥" + _vm._s(_vm.checkData.price)),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleBuy },
                        },
                        [_vm._v(" 购买此考试 ")]
                      ),
                    ],
                    1
                  ),
                ]),
          ]),
          !_vm.disabled
            ? _c("face-check-dialog", {
                attrs: {
                  visible: _vm.faceDialog,
                  params: { examId: _vm.postForm.examId },
                  url: "/api/paper/capture/face-check",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.faceDialog = $event
                  },
                  success: _vm.faceBack,
                },
              })
            : _vm._e(),
          !_vm.disabled
            ? _c("exam-apply-dialog", {
                attrs: {
                  "exam-id": _vm.postForm.examId,
                  visible: _vm.applyVisible,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.applyVisible = $event
                  },
                },
              })
            : _vm._e(),
          _vm.checkData.examType === "1"
            ? _c("simple-device-test", {
                attrs: { visible: _vm.deviceVisible },
                on: {
                  "update:visible": function ($event) {
                    _vm.deviceVisible = $event
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "settle-box" }, [
      _c("div", [_vm._v("1、多次考试取最后一次作为最终成绩；")]),
      _c("div", [_vm._v("2、如有积分和证书将在考试结束后统一发放；")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }