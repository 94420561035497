/**
 * 加法
 * @param arg1
 * @param arg2
 * @returns {string}
 */
const add = function (arg1, arg2) {
  let r1, r2
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  const m = Math.pow(10, Math.max(r1, r2))
  const n = r1 >= r2 ? r1 : r2
  return ((arg1 * m + arg2 * m) / m).toFixed(n)
}

/**
 * 乘法
 * @param arg1
 * @param arg2
 * @returns {number}
 */
const mul = function (arg1, arg2) {
  let m = 0
  const s1 = arg1.toString()
  const s2 = arg2.toString()
  try {
    m += s1.split('.')[1].length
  } catch (e) {
    console.log(e)
  }
  try {
    m += s2.split('.')[1].length
  } catch (e) {
    console.log(e)
  }

  return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m)
}

/**
 * 减法
 * @param arg1
 * @param arg2
 * @returns {string}
 */
const sub = function (arg1, arg2) {
  let re1, re2
  try {
    re1 = arg1.toString().split('.')[1].length
  } catch (e) {
    re1 = 0
  }
  try {
    re2 = arg2.toString().split('.')[1].length
  } catch (e) {
    re2 = 0
  }
  const m = Math.pow(10, Math.max(re1, re2))
  const n = re1 >= re2 ? re1 : re2
  return ((arg1 * m - arg2 * m) / m).toFixed(n)
}

/**
 * 除法
 * @param arg1
 * @param arg2
 * @param digit
 * @returns {number}
 */
const div = function (arg1, arg2, digit) {
  let t1 = 0
  let t2 = 0
  try {
    t1 = arg1.toString().split('.')[1].length
  } catch (e) {
    console.log(e)
  }
  try {
    t2 = arg2.toString().split('.')[1].length
  } catch (e) {
    console.log(e)
  }

  const r1 = Number(arg1.toString().replace('.', ''))
  const r2 = Number(arg2.toString().replace('.', ''))
  // 获取小数点后的计算值
  const result = ((r1 / r2) * Math.pow(10, t2 - t1)).toString()
  let result2 = result.split('.')[1]
  result2 = result2.substring(0, digit > result2.length ? result2.length : digit)

  return Number(result.split('.')[0] + '.' + result2)
}

export default {
  add,
  mul,
  sub,
  div,
}
