var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.paperData && _vm.paperData.id
    ? _c(
        "div",
        { staticClass: "exam-ps" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "lay-box" },
                    [
                      _c(
                        "div",
                        [
                          _vm.paperData.examType === "1" &&
                          _vm.paperData.camOn &&
                          _vm.paperData.camInterval > 0
                            ? _c("exam-cam-normal", {
                                attrs: {
                                  "cam-on": _vm.paperData.camOn,
                                  "cam-interval": _vm.paperData.camInterval,
                                  "paper-id": _vm.paperData.id,
                                },
                                on: { break: _vm.handleBreak },
                              })
                            : _vm._e(),
                          _vm.paperData.examType === "2"
                            ? _c("exam-cam-rtc", {
                                attrs: {
                                  "cam-on": _vm.paperData.camOn,
                                  "cam-interval": _vm.paperData.camInterval,
                                  "exam-id": _vm.paperData.examId,
                                  "paper-id": _vm.paperData.id,
                                },
                                on: { break: _vm.handleBreak },
                              })
                            : _vm._e(),
                          (_vm.paperData.examType === "1" &&
                            _vm.paperData.camOn &&
                            _vm.paperData.camInterval > 0) ||
                          (_vm.paperData.examType === "2" &&
                            _vm.paperData.camOn)
                            ? _c("div", { staticClass: "cam-box" }, [
                                _c("strong", [_vm._v("监控中")]),
                                _vm._v(" 确保您的正脸在摄像头范围内！ "),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            "font-weight": "700",
                            background: "#efefef",
                            padding: "10px 5px",
                            color: "#0a84ff",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.paperData.title) + " ")]
                      ),
                      _vm._l(_vm.paperData.groupList, function (type) {
                        return _c("div", { key: type.id }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(" " + _vm._s(type.title) + " "),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  float: "right",
                                  "font-size": "12px",
                                },
                              },
                              [
                                _vm._v(
                                  "总" +
                                    _vm._s(type.quCount) +
                                    "题/共" +
                                    _vm._s(type.totalScore) +
                                    "分"
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "card-box" },
                            _vm._l(type.quList, function (item) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  class: {
                                    "sub-card":
                                      item.subList && item.subList.length > 0,
                                  },
                                },
                                [
                                  !item.subList || item.subList.length === 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "item",
                                          on: {
                                            click: function ($event) {
                                              return _vm.fetchQuData(item.quId)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              class: {
                                                num: true,
                                                right:
                                                  item.answered &&
                                                  _vm.currentId !== item.quId,
                                                current:
                                                  _vm.currentId === item.quId,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.sort) + " "
                                              ),
                                            ]
                                          ),
                                          _c("div", { staticClass: "flag" }, [
                                            item.mark
                                              ? _c("i", {
                                                  staticClass: "el-icon-s-flag",
                                                  staticStyle: {
                                                    color: "#ff0000",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]),
                                        ]
                                      )
                                    : _vm._l(item.subList, function (sub) {
                                        return _c(
                                          "div",
                                          {
                                            key: sub.id,
                                            staticClass: "item",
                                            on: {
                                              click: function ($event) {
                                                return _vm.fetchQuData(
                                                  item.quId,
                                                  sub.quId
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                class: {
                                                  num: true,
                                                  right:
                                                    sub.answered &&
                                                    _vm.currentSub !== sub.quId,
                                                  current:
                                                    _vm.currentSub === sub.quId,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.sort) +
                                                    "." +
                                                    _vm._s(sub.sort) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c("div", { staticClass: "flag" }, [
                                              sub.mark
                                                ? _c("i", {
                                                    staticClass:
                                                      "el-icon-s-flag",
                                                    staticStyle: {
                                                      color: "#ff0000",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]),
                                          ]
                                        )
                                      }),
                                ],
                                2
                              )
                            }),
                            0
                          ),
                        ])
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 17 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "lay-box", attrs: { id: "exam-body" } },
                    [
                      _vm.paperData.answerMode === 1
                        ? _c("ExamStepMode", {
                            ref: "examStepMode",
                            attrs: { paper: _vm.paperData },
                            on: {
                              action: _vm.handleAction,
                              saved: _vm.saveBack,
                            },
                          })
                        : _c("ExamFullMode", {
                            ref: "examFullMode",
                            attrs: { paper: _vm.paperData },
                            on: {
                              action: _vm.handleAction,
                              focus: _vm.handleFocus,
                              saved: _vm.saveBack,
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "lay-box" },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "line-height": "30px",
                          },
                          attrs: { gutter: 10 },
                        },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _vm._v(" 剩余时间 "),
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c("exam-timer", {
                                ref: "examTimer",
                                attrs: { "paper-id": _vm.paperData.id },
                                on: {
                                  timeout: function ($event) {
                                    return _vm.handWithEx(0)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [_c("el-divider")],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "warning" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.doHandler(0)
                                    },
                                  },
                                },
                                [_vm._v("提交试卷")]
                              ),
                            ],
                            1
                          ),
                          _vm.paperData.actionOn &&
                          _vm.paperData.actionInterval > 0
                            ? _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c("el-divider"),
                                  _c("action-checker", {
                                    attrs: {
                                      "action-on": _vm.paperData.actionOn,
                                      "action-interval":
                                        _vm.paperData.actionInterval,
                                      trigger: _vm.actionTrigger,
                                    },
                                    on: {
                                      break: function ($event) {
                                        return _vm.doHandler(4)
                                      },
                                    },
                                  }),
                                  _vm._v(" 秒内无任何操作将自动交卷！ "),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [_c("el-divider")],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: !_vm.showPrevious,
                                    icon: "el-icon-back",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleNext(-1)
                                    },
                                  },
                                },
                                [_vm._v("上一题")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: { "padding-top": "20px" },
                              attrs: { span: 24 },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: !_vm.showNext,
                                    type: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleNext(1)
                                    },
                                  },
                                },
                                [
                                  _vm._v("下一题"),
                                  _c("i", {
                                    staticClass: "el-icon-right el-icon--right",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.paperData.leaveOn && _vm.paperData.leaveCount > 0
            ? _c("leave-checker", {
                attrs: {
                  "leave-on": _vm.paperData.leaveOn,
                  "leave-check": _vm.paperData.leaveCheck,
                  "leave-count": _vm.paperData.leaveCount,
                  "paper-id": _vm.paperData.id,
                },
                on: {
                  back: _vm.leaveBack,
                  break: function ($event) {
                    return _vm.handleBreak("因切屏次数超限，系统已强制交卷！")
                  },
                },
              })
            : _vm._e(),
          _c("exam-socket", {
            attrs: { "exam-id": _vm.paperData.examId, platform: "pc" },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }