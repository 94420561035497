import { download, post } from '@/utils/request'

/**
 * 题库详情
 * @param data
 */
export function fetchDetail(data) {
  return post('/api/repo/detail', data)
}

/**
 * 保存题库
 * @param data
 */
export function saveData(data) {
  return post('/api/repo/save', data)
}

/**
 * 保存题库
 * @param data
 */
export function fetchList(data) {
  return post('/api/repo/paging', data)
}

/**
 * 题库批量操作
 * @param data
 */
export function batchAction(data) {
  return post('/api/repo/batch-action', data)
}

/**
 * 题型群组统计
 * @param data
 */
export function typeGroup(data) {
  return post('/api/repo/type-group', data)
}

/**
 * 题型群组统计
 * @param data
 */
export function chapterGroup(data) {
  return post('/api/repo/chapter-group', data)
}

/**
 * 创建预付费订单
 * @param id
 * @returns {Promise}
 */
export function buy(repoId) {
  return post('/api/repo/buy/init', { repoId: repoId })
}

/**
 * 职业
 * @param id
 * @returns {Promise}
 */
export function occupation(data) {
  return post('/api/career/pageCareer', data)
}

/**
 * 工种
 * @param id
 * @returns {Promise}
 */
export function workKind(data) {
  return post('/api/career/pageWork', data)
}

/**
 * 新增或修改题库
 * @param data
 * @returns {Promise}
 */
export function revisedQuestion(data) {
  return post('/api/repo/save', data)
}

/**
 * 工种下拉
 * @param id
 * @returns {Promise}
 */
export function workKindSelect(data) {
  return post('/api/career/listWork', data)
}

/**
 * 删除细目表-科目
 * @param id
 * @returns {Promise}
 */
export function delSubject(data) {
  return post('/api/repo/chapter/delete/subject', data)
}

/**
 * 当前用户部门
 * @param data
 * @returns {Promise}
 */
export function currentUserDepartment(data) {
  return post('/api/exam/exam/departments', data)
}
