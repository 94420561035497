<template>
  <div>
    <div class="watch-box">
      <div class="watch-left">
        <el-card class="watch-left-card">
          自动翻页：
          <div>
            <el-switch v-model="autoPage" active-text="开" inactive-text="关" />
          </div>
          <div>刷新时间：</div>
          <div><el-input-number v-model="refreshTime" :min="5" :max="999" size="mini" /> 秒</div>
          <div style="font-size: 12px; color: #0a84ff">即将在{{ seconds }}秒后刷新</div>
          <el-divider />
          <div style="color: #555; line-height: 30px">
            <div>应考人数：{{ stat.requireCount }}人</div>
            <div>在考人数：{{ stat.processCount }}人</div>
            <div>交卷人数：{{ stat.handCount }}人</div>
            <div>缺考人数：{{ stat.missCount }}人</div>
          </div>
          <el-divider />
          <div v-if="stat.processCount > 0">
            <el-button type="primary" size="small" style="width: 100%" @click="batchDialog">群发消息</el-button>
          </div>
          <div v-if="stat.processCount > 0" style="padding-top: 10px">
            <el-button type="danger" size="small" style="width: 100%" @click="showHandAll">全部交卷</el-button>
          </div>
        </el-card>
      </div>

      <div class="watch-right">
        <data-grid
          v-if="stat.processCount > 0"
          ref="dataGrid"
          :options="options"
          :list-query="listQuery"
          @query-change="queryChange"
        >
          <template #filter-content>
            <div style="padding: 0 0 10px 0; display: flex; align-items: center">
              <depart-tree-select v-model="listQuery.params.deptCode" width="200" />
              <el-input
                v-model="listQuery.params.realName"
                placeholder="搜索姓名"
                size="small"
                style="width: 200px; margin-left: 10px"
              />
              <el-checkbox v-model="listQuery.params.online" label="在线" style="margin-left: 10px" />
              <el-checkbox v-model="listQuery.params.offline" label="离线" />
            </div>
          </template>

          <template v-slot:data-item="{ data }">
            <div>
              <slot :data="data" name="user-item" />
              <div class="watch-line-box">
                <div class="user-name">{{ data.realName }}</div>
                <div class="user-state">
                  <span :class="{ online: data.online, offline: !data.online }">
                    {{ data.online ? '在线' : '离线' }}
                  </span>
                </div>
              </div>

              <div class="watch-ps-box">答题进度:{{ data.answerCount }}/{{ data.quCount }}</div>

              <div class="watch-act-box">
                <el-button
                  :disabled="!data.online"
                  style="width: 50%"
                  type="primary"
                  size="mini"
                  plain
                  @click="singleDialog(data.userId)"
                  >发消息</el-button
                >
                <el-button type="danger" size="mini" style="width: 50%" plain @click="handDialog(data.id)"
                  >交卷</el-button
                >
              </div>
            </div>
          </template>
        </data-grid>

        <div v-else style="display: flex; align-items: center; height: 60vh; justify-content: center">
          <el-empty description="没有正在考试的用户！" />
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="handVisible"
      :close-on-click-modal="false"
      title="全部收卷"
      width="500px"
      @close="handVisible = false"
    >
      <el-form ref="handForm" :model="handForm" :rules="handFormRules" label-position="left" label-width="100px">
        <el-form-item label="考卷状态" prop="exState">
          <dic-list-select v-model="handForm.exState" style="width: 100%" dic-code="paper_ex_state" />
        </el-form-item>

        <el-form-item label="收卷理由" prop="message">
          <el-input v-model="handForm.message" type="textarea" />
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="handVisible = false">取 消</el-button>
        <el-button :loading="handLoading" type="primary" @click="doHandAll">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { watchStat, forceHand, forceHandAll, batchMsg, singleMsg } from '@/api/exam/watch'

export default {
  name: 'WatchFrame',
  props: {
    examId: String,
    lineCount: Number,
  },
  data() {
    return {
      autoPage: false,
      refreshTime: 10,
      seconds: 10,
      paused: false,

      // 统计信息
      stat: {
        requireCount: 0,
        processCount: 0,
        handCount: 0,
        missCount: 0,
      },

      listQuery: {
        current: 1,
        size: 32,
        params: {
          examId: this.examId,
          handState: 0,
          online: true,
          offline: true,
        },
      },
      options: {
        lineCount: 8,
        // 列表请求URL
        listUrl: '/api/exam/watch/process-paging',
      },

      // 自动翻页
      pageTimer: null,
      showTimer: null,

      handVisible: false,
      handForm: {},
      handFormRules: {
        exState: [{ required: true, message: '考卷状态必须选择！' }],
        message: [{ required: true, message: '收卷理由必须填写！' }],
      },
      handLoading: false,
    }
  },

  watch: {
    examId: {
      handler(val) {
        this.listQuery.params.examId = val
        this.fetchStat()
      },
    },

    // 自动翻页
    autoPage: {
      handler() {
        this.handleRefresh()
      },
    },
    refreshTime: {
      handler() {
        this.handleRefresh()
      },
    },
  },
  beforeDestroy() {
    clearInterval(this.pageTimer)
    clearInterval(this.showTimer)
  },

  created() {
    // 获取考试信息
    this.listQuery.params.examId = this.examId
    this.options.lineCount = this.lineCount
    // 获取统计信息
    this.fetchStat()
  },
  methods: {
    // 获取统计信息
    fetchStat() {
      if (!this.examId) {
        return
      }
      watchStat({ id: this.examId }).then((res) => {
        this.stat = res.data
        // 同步统计数据
        this.$emit('stat', this.stat)
        // 刷新列表
        this.handleRefresh()
      })
    },

    // 列表数据变化
    queryChange() {
      this.$emit('query-change')
    },

    // 显示刷新
    showHandler() {
      if (this.showTimer) {
        clearInterval(this.showTimer)
      }

      this.showTimer = setInterval(() => {
        if (this.paused) {
          return
        }
        // 循环
        if (this.seconds <= 0) {
          this.seconds = this.refreshTime
        }

        this.seconds -= 1
      }, 1000)
    },

    // 自动翻页
    handleRefresh() {
      if (this.pageTimer) {
        clearInterval(this.pageTimer)
        this.seconds = this.refreshTime
      }
      // 自动翻页的
      if (this.autoPage) {
        this.pageTimer = setInterval(() => {
          if (this.paused) {
            return
          }
          this.fetchStat()
          if (this.$refs.dataGrid) {
            this.$refs.dataGrid.nextPage()
          }
        }, this.refreshTime * 1000)
      } else {
        // 普通刷新
        this.pageTimer = setInterval(() => {
          if (this.paused) {
            return
          }
          this.fetchStat()
          if (this.$refs.dataGrid) {
            this.$refs.dataGrid.getList()
          }
        }, this.refreshTime * 1000)
      }
      this.showHandler()
    },

    // 单人消息
    singleDialog(toUserId) {
      this.paused = true
      this.$prompt('输入提醒消息', '单人消息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        singleMsg({ examId: this.examId, userId: toUserId, message: value }).then(() => {
          this.paused = false
          this.$message.success('消息发送成功！')
        })
      })
    },

    // 批量消息
    batchDialog() {
      this.paused = true
      this.$prompt('输入提醒消息', '批量消息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        batchMsg({ examId: this.examId, message: value }).then((res) => {
          const data = res.data
          this.$message.success('消息发送结果，成功：' + data.success + '，失败：' + data.fail)
        })
      })
    },

    // 单个强制交卷
    handDialog(id) {
      this.paused = true
      this.$prompt('输入强制交卷理由', '强制交卷', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        forceHand({ paperId: id, message: value }).then(() => {
          this.$message.success('操作成功！')
          if (this.$refs.dataGrid) {
            this.$refs.dataGrid.getList()
          }
          this.fetchStat()
          this.paused = false
        })
      })
    },

    // 全部交卷对话框
    showHandAll() {
      this.handVisible = true
      this.paused = true
    },

    // 执行全部交卷
    doHandAll() {
      this.$refs.handForm.validate((valid) => {
        if (!valid) {
          return
        }
        this.$confirm('交卷后考生将无法继续考试，确定要全部交卷吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          const params = this.handForm
          params.examId = this.examId
          this.handLoading = true
          forceHandAll(params).then(() => {
            this.$message.success('操作成功！')
            this.fetchStat()
            this.paused = false
            this.handLoading = false
            this.handVisible = false
          })
        })
      })
    },
  },
}
</script>

<style scoped>
.watch-box {
  display: flex;
  height: calc(100vh - 180px);
}

.watch-left {
  width: 200px;
  flex-shrink: 0;
  line-height: 36px;
  font-weight: 700;
  font-size: 14px;
  flex-direction: column;
}

.watch-left-card {
  width: 100%;
  height: calc(100vh - 180px);
  overflow-y: auto;
}

.watch-right {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 20px;
}

.watch-act-box {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}
.watch-ps-box {
  font-size: 12px;
  color: #888;
}

.watch-line-box {
  display: flex;
  align-items: center;
}

.watch-line-box > .user-name {
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 700;
}

.watch-line-box > .user-state {
  width: 50px;
  text-align: right;
}

.watch-line-box > .user-state > .online {
  color: #1aac1a;
  font-size: 12px;
}

.watch-line-box > .user-state > .offline {
  color: #ccc;
  font-size: 12px;
}
</style>
