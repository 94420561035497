<template>
  <el-card style="height: 360px">
    <div style="color: #000; font-weight: 700; font-size: 16px; margin-bottom: 10px">考试总览</div>

    <div :id="id" :class="className" :style="{ height: height, width: width }" />
  </el-card>
</template>

<script>
import * as echarts from 'echarts'
import resize from '../../mixins/resize'
import { dashExamWeek } from '@/api/stat/stat'

export default {
  name: 'LineChartExam',
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    id: {
      type: String,
      default: 'chart',
    },
    width: {
      type: String,
      default: '200px',
    },
    height: {
      type: String,
      default: '200px',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      chart: null,
    }
  },
  mounted() {
    dashExamWeek().then((res) => {
      this.parseData(res.data)
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    // 构建统计数据
    parseData(data) {
      const dateList = []
      const examCountList = []
      const examUserList = []
      const passUserList = []

      for (let i = 0; i < data.length; i++) {
        const stat = data[i]
        dateList.push(stat.dateStr)
        examCountList.push(stat.examCount)
        examUserList.push(stat.examUser)
        passUserList.push(stat.passUser)
      }

      const option = {
        title: {
          text: '',
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['考试人次', '考试人数', '通过人数'],
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: dateList,
        },
        yAxis: {
          type: 'value',
          minInterval: 1,
        },
        series: [
          {
            name: '考试人次',
            type: 'line',
            data: examCountList,
          },
          {
            name: '考试人数',
            type: 'line',
            data: examUserList,
          },
          {
            name: '通过人数',
            type: 'line',
            data: passUserList,
          },
        ],
      }

      this.initChart(option)
    },

    initChart(option) {
      this.chart = echarts.init(document.getElementById(this.id))
      this.chart.setOption(option)
    },
  },
}
</script>
