<template>
  <el-form ref="postForm" :model="postForm" :rules="rules" @submit.native.prevent>
    <el-form-item prop="captchaValue">
      <el-input v-model="postForm.smsCode" placeholder="输入验证码" prefix-icon="el-icon-chat-line-round">
        <el-popover
          slot="append"
          v-model="showSmsCaptcha"
          placement="right"
          width="400"
          trigger="click"
          @show="checkMobile"
        >
          <div style="line-height: 50px">
            <yf-captcha ref="captcha" v-model="postForm" />
            <el-button :loading="loading" type="primary" @click="sendSms">发送</el-button>
          </div>
          <el-button slot="reference">
            <span v-if="left > 0">{{ left }}秒</span>
            <span v-else>发送验证码</span>
          </el-button>
        </el-popover>
      </el-input>
    </el-form-item>
  </el-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { isMobile } from '@/utils/validate'
import { send } from '@/api/sys/user/sms'

export default {
  name: 'SmsInput',
  components: {},
  props: {
    value: {
      type: Object,
      default: function () {
        return {
          smsType: '',
          mobile: '',
          captchaKey: '',
          captchaValue: '',
        }
      },
    },
    type: Number,
  },
  data() {
    return {
      showSmsCaptcha: false,
      left: 0,
      timer: null,
      postForm: {},
      loading: false,
      rules: {
        mobile: [
          {
            required: true,
            validator: isMobile,
          },
        ],
        captchaValue: {
          rules: [
            {
              required: true,
              message: '图形验证码不能为空！',
            },
          ],
        },
      },
    }
  },
  computed: {
    ...mapGetters(['siteData']),
  },

  watch: {
    // 传递参数
    postForm: {
      handler(val) {
        // 发送验证码
        this.$emit('input', val)
      },
      deep: true,
    },

    // 检测查询变化
    value: {
      handler(val) {
        this.postForm = val
      },
      deep: true,
    },
  },
  created() {
    this.postForm = this.value
  },
  beforeDestroy() {
    this.left = 0
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    // 表单验证
    async validate() {
      // 返回结果
      return await this.$refs.postForm
        .validate()
        .then((valid) => {
          return valid
        })
        .catch(() => {
          return false
        })
    },

    // 校验手机号码
    checkMobile() {
      if (this.left > 0) {
        this.showSmsCaptcha = false
        return
      }

      const that = this

      isMobile(null, this.postForm.mobile, function (res) {
        if (res) {
          that.$message.error(res)
          that.showSmsCaptcha = false
        }
      })
    },

    async sendSms() {
      if (this.left > 0) {
        this.showSmsCaptcha = false
        return
      }

      if (this.timer) {
        clearInterval(this.timer)
      }

      // 消息框
      const check = await this.$refs.captcha.validate().then((valid) => {
        return valid
      })

      if (!check) {
        return
      }

      // 表单验证
      this.$refs.postForm.validate().then((valid) => {
        if (!valid) {
          return
        }
        this.loading = true

        // 追加参数
        this.postForm.smsType = this.type

        send(this.postForm)
          .then(() => {
            this.left = 60
            this.timer = setInterval(this.calc, 1000)
            this.showSmsCaptcha = false
            this.$message.success('短信发送成功，请查收手机短信！')
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      })
    },

    calc() {
      this.left -= 1
      if (this.left === 0) {
        clearInterval(this.timer)
      }
    },
  },
}
</script>
