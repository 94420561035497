<template>
  <div v-loading="loading" class="app-container">
    <!-- 显示感谢文字 -->
    <thanks-only v-if="paperData.resultType === 1" :data="paperData" />

    <!-- 显示感谢文字加分数 -->
    <thanks-with-score v-if="paperData.resultType === 2" :data="paperData" />

    <!-- 显示完整解析详情 -->
    <result-detail v-if="paperData.resultType === 3" :data="paperData" />
  </div>
</template>

<script>
import { paperResult } from '@/api/paper/exam'
import ThanksOnly from '@/views/web/exam/components/QuResultPage/ThanksOnly.vue'
import ThanksWithScore from '@/views/web/exam/components/QuResultPage/ThanksWithScore.vue'
import ResultDetail from '@/views/web/exam/components/QuResultPage/ResultDetail.vue'

export default {
  components: { ThanksOnly, ThanksWithScore, ResultDetail },
  data() {
    return {
      // 加载状态
      loading: false,
      // 试卷ID
      paperData: {
        resultType: null,
        quList: [],
      },
    }
  },

  created() {
    const id = this.$route.params.id
    if (id !== undefined) {
      this.fetchData(id)
    }
  },
  methods: {
    // 获取试卷信息
    fetchData(id) {
      this.loading = true
      const params = { id: id }
      paperResult(params)
        .then((response) => {
          // 试卷内容
          this.paperData = response.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>
