<template>
  <web-socket
    v-if="examId != null && examId !== ''"
    :url="`/api/socket/exam/${userId}/${examId}/${platform}`"
    @receive="onMessage"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ExamSocket',
  components: {},
  props: {
    examId: String,
    platform: String,
  },

  data() {
    return {
      msgShow: false,
    }
  },
  computed: {
    ...mapGetters(['userId']),
  },
  methods: {
    // 收到消息
    onMessage(data) {
      if (data !== 'pong') {
        this.$notify({
          title: '管理员提醒',
          type: 'warning',
          message: data,
          duration: 0,
        })
      }
    },
  },
}
</script>
