var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "full-box" } },
    _vm._l(_vm.paperData.groupList, function (group, index) {
      return _c(
        "div",
        { key: group.id },
        [
          _c("div", { staticClass: "group-tt" }, [
            _vm._v(
              " 第" +
                _vm._s(index + 1) +
                "大题，" +
                _vm._s(group.title) +
                "(共" +
                _vm._s(group.quCount) +
                "题,每题" +
                _vm._s(group.perScore) +
                "分,合计" +
                _vm._s(group.totalScore) +
                "分) "
            ),
          ]),
          _vm._l(group.quList, function (qu) {
            return _c(
              "div",
              { key: qu.id },
              [
                _c("qu-item-exam", {
                  attrs: { value: qu, "show-type": true },
                  on: {
                    fill: _vm.handleSave,
                    focus: _vm.handleFocus,
                    action: _vm.handleAction,
                  },
                }),
              ],
              1
            )
          }),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }