<template>
  <data-grid :options="options" :list-query="listQuery">
    <template slot="filter-content">
      <div style="display: flex; align-items: center">
        <div>
          <el-input
            v-model="listQuery.params.title"
            class="filter-item"
            size="small"
            placeholder="搜索公告标题"
            prefix-icon="el-icon-search"
            style="width: 300px"
          />
        </div>
      </div>
    </template>
    <template v-slot:data-item="{ data }">
      <div class="not-box">
        <div style="flex-grow: 1">
          <el-link type="primary" @click="toDetail(data.id)"> {{ data.title }}</el-link>
        </div>
        <div class="font-second" style="text-align: right; font-style: italic; font-weight: 700">
          {{ data.createTime }}
        </div>
      </div>
      <div class="font-small">{{ data.contentText }}</div>
    </template>
  </data-grid>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      listQuery: {
        current: 1,
        size: 10,
        params: {
          state: 0,
        },
      },

      options: {
        // 列表请求URL
        listUrl: '/api/sys/notice/paging',
      },
    }
  },

  methods: {
    toDetail(id) {
      this.$router.push({ name: 'NoticeDetail', params: { id: id } })
    },
  },
}
</script>

<style scoped>
.not-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

::v-deep .el-link {
  font-size: 16px;
  font-weight: 700;
}
</style>
