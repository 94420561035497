/* eslint-disable prettier/prettier */
const watermark = {}

const id = 'set-customer-watermark-id'
let timer = null

const setWatermark = (dom, str) => {
  if (document.getElementById(id) !== null) {
    dom ? dom.removeChild(document.getElementById(id)) : document.body.removeChild(document.getElementById(id))
  }

  const left = (window.innerWidth / 24) * 4 + 20
  const width = (window.innerWidth / 24) * 17 - 40
  const height = window.innerHeight - 125

  // 创建一个画布
  const can = document.createElement('canvas')
  can.width = 280
  can.height = 150

  // 设置画布样式
  const cans = can.getContext('2d')
  cans.rotate((-40 * Math.PI) / 180)
  cans.font = '15px Vedana'
  cans.fillStyle = 'rgba(0,0,0,0.15)'
  cans.textAlign = 'left'
  cans.textBaseline = 'Middle'
  cans.fillText(str, 0, can.height)

  // 创建整体容器，设置样式
  const div = document.createElement('div')

  div.id = id
  div.style.pointerEvents = 'none'
  div.style.top = '100px'
  div.style.left = `${left}px`
  div.style.position = 'absolute'
  div.style.zIndex = '1000'
  div.style.width = `${width}px`
  div.style.height = `${height}px`
  div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat'

  dom ? dom.appendChild(div) : document.body.appendChild(div)
  return id
}

// 设置水印
watermark.set = (dom, str) => {
  let id = setWatermark(dom, str)
  timer = setInterval(() => {
    if (document.getElementById(id) === null) {
      id = setWatermark(dom, str)
    }
  }, 500)
  window.onresize = () => {
    setWatermark(dom, str)
  }
}

// 清除水印
watermark.remove = () => {
  // 移除定时器
  if (timer) {
    clearInterval(timer)
  }

  // 移除
  const dom = document.getElementById(id)
  if (dom) {
    dom.remove()
  }
}

export default watermark
