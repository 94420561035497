<template>
  <div v-if="quData" :class="{ 'sub-box': sub }" class="qu-box">
    <div class="qu-item">
      <div :class="sub ? 'sub' : 'num'">{{ quData.sort }}</div>

      <div class="content">
        <div>【{{ quData.quType_dictText }}】</div>
        <div v-html="$options.filters.formatRichText(quData.content)" />
      </div>
    </div>

    <!-- 单选多选判断 -->
    <div v-if="quData.quType === '1' || quData.quType === '2' || quData.quType === '3'" class="qu-answer">
      <div
        v-for="(item, index) in quData.answerList"
        :key="index"
        :class="{ active: item.checked || (mode === 'repo_rem' && item.isRight) }"
        class="item"
        @click="handleCheck(item)"
      >
        <div class="tag">{{ item.tag }}</div>
        <div class="content">
          <div>{{ item.content }}</div>
          <div v-if="item.image">
            <img :src="item.image" />
          </div>
        </div>
        <div v-if="item.isRight && (cardItem.answered || mode === 'repo_rem')" class="right">
          <i class="el-icon-success" style="color: #0d5ea5" />
          答案
        </div>
      </div>
    </div>

    <!-- 主观题 -->
    <div v-if="quData.quType === '4'">
      <div style="font-weight: 700; color: #0a84ff">题目解析：</div>

      <div v-if="quData.analysis" class="as-box" v-html="$options.filters.formatRichText(quData.analysis)" />
      <div v-else>暂无试题解析!</div>

      <el-alert
        v-if="mode !== 'repo_rem'"
        :closable="false"
        style="margin-top: 20px"
        title="请您详细阅读解析并点击下方的`确认答案`即可完成训练"
        type="error"
      />
    </div>

    <!-- 填空题 -->
    <div v-if="quData.quType === '5' && mode !== 'repo_rem'" class="qu-answer">
      <div v-for="item in quData.answerList" :key="item.id" class="item">
        <div class="content">
          <el-input v-model="item.answer" :disabled="cardItem.answered" />
        </div>
      </div>
    </div>

    <!-- 组合题引用自己 -->
    <div v-if="quData.quType === '99'">
      <div v-for="sub in quData.subList" :key="sub.id">
        <qu-item-train :value="sub" :card="cardItem" :sub="true" :mode="mode" />
      </div>
    </div>

    <!-- 背题模式或已答都显示答案 -->
    <div v-if="(cardItem.answered || mode === 'repo_rem') && quData.quType !== '4'">
      <div style="font-weight: 700; color: #cc0000">{{ sub ? '小题解析：' : '大题解析：' }}</div>
      <!-- 填空题 -->
      <div class="as-box">
        <div v-if="quData.quType === '5'">
          <div v-for="item in quData.answerList" :key="item.id">{{ item.content }}</div>
        </div>
        <div v-else>
          <div v-if="quData.analysis" v-html="$options.filters.formatRichText(quData.analysis)" />
          <div v-else>暂无题目分析内容！</div>
        </div>
      </div>
    </div>

    <el-button
      v-if="
        mode !== 'repo_rem' &&
        !cardItem.answered &&
        !sub &&
        (quData.quType === '2' || quData.quType === '4' || quData.quType === '5' || quData.quType === '99')
      "
      style="margin-top: 20px"
      type="warning"
      size="small"
      round
      @click="checkAnswer"
      >确认答案</el-button
    >

    <div v-if="sub" class="split" />
  </div>
</template>

<script>
import { fillResult } from '@/api/repo/train'

export default {
  name: 'QuItemTrain',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    card: {
      type: Object,
      default: () => ({}),
    },
    sub: {
      type: Boolean,
      default: false,
    },
    mode: String,
  },

  data() {
    return {
      // 是否已答
      tags: [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ],
      cardItem: {},
      // 当前试题内容
      quData: {
        quType: '',
        answerList: [],
      },
      loading: false,
    }
  },

  watch: {
    card: {
      handler() {
        this.cardItem = this.card
      },
    },

    value: {
      handler() {
        this.quData = this.value
        this.initValues()
      },
    },
  },

  created() {
    this.cardItem = this.card
    this.quData = this.value
    this.initValues()
  },

  methods: {
    // 检查答案是否正确
    checkAnswer() {
      if (this.sub) {
        return
      }

      // 整体提交过去
      const params = this.quData
      this.loading = true

      fillResult(params).then((res) => {
        // 回调
        this.$emit('rest', res.data)
        this.loading = false
      })
    },

    // 初始化填入值
    initValues() {
      const answers = this.quData.answerList
      if (answers && answers.length > 0) {
        for (let i = 0; i < answers.length; i++) {
          // 动态添加属性
          this.$set(answers[i], 'tag', this.tags[i])
        }
      }
    },

    // 选定值
    handleCheck(item) {
      // 不允许重复回答
      if (this.cardItem.answered) {
        return
      }

      let auto = false

      // 单选题直接保答案
      if (this.quData.quType === '1' || this.quData.quType === '3') {
        // 自动提交
        auto = true
        // 互斥答案
        const answers = this.quData.answerList
        for (let i = 0; i < answers.length; i++) {
          answers[i].checked = false
        }
      }

      // 选中或取消
      item.checked = !item.checked

      if (auto) {
        this.checkAnswer()
      }
    },
  },
}
</script>
