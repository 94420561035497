var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "考生列表", visible: _vm.show1, width: "1000px" },
          on: {
            "update:visible": function ($event) {
              _vm.show1 = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.tableData } },
            [
              _c("el-table-column", {
                attrs: { prop: "realName", label: "姓名", width: "180" },
              }),
              _c("el-table-column", {
                attrs: { prop: "face", label: "人脸识别", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            scope.row.face
                              ? _c("el-image", {
                                  staticStyle: {
                                    width: "50px",
                                    height: "50px",
                                  },
                                  attrs: {
                                    src: scope.row.face,
                                    "preview-src-list": [scope.row.face],
                                  },
                                })
                              : _c("el-image", { staticClass: "img" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    },
                                    [_vm._v("暂无")]
                                  ),
                                ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "faceState", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.faceState ? "已验证" : "未验证"
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "operation", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.showFace(scope.row)
                              },
                            },
                          },
                          [_vm._v("人脸识别")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.show2
        ? _c("face-check-dialog", {
            attrs: {
              visible: _vm.show2,
              params: { examId: _vm.examId, id: _vm.nowItem.userId },
              url: "/api/exam/watch/face-check",
            },
            on: {
              "update:visible": function ($event) {
                _vm.show2 = $event
              },
              success: _vm.faceBack,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }