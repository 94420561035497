var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.dialogVisible,
            title: "菜单管理",
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "postForm",
              attrs: {
                model: _vm.postForm,
                rules: _vm.rules,
                "label-width": "120px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "菜单类型", prop: "menuType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.postForm.menuType,
                        callback: function ($$v) {
                          _vm.$set(_vm.postForm, "menuType", $$v)
                        },
                        expression: "postForm.menuType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("菜单")]),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("功能")]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "font-size": "12px", color: "#ff4949" } },
                    [
                      _vm._v(
                        " 顶级菜单、二级菜单等要在后台左侧展示的，一般选择菜单；按钮、不在左侧菜单展示、增删查改操作一般选择功能 "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上级菜单", prop: "parentId" } },
                [
                  _c("menu-select-tree", {
                    ref: "tree",
                    model: {
                      value: _vm.postForm.parentId,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "parentId", $$v)
                      },
                      expression: "postForm.parentId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "菜单标题", prop: "metaTitle" } },
                [
                  _c("el-input", {
                    attrs: { size: "small" },
                    model: {
                      value: _vm.postForm.metaTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "metaTitle", $$v)
                      },
                      expression: "postForm.metaTitle",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "菜单名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      placeholder: "应为英文，用于前端程序编码",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        _vm.postForm.name = _vm.postForm.name.replace(
                          /[^a-zA-Z]/g,
                          ""
                        )
                      },
                    },
                    model: {
                      value: _vm.postForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "name", $$v)
                      },
                      expression: "postForm.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "访问路径", prop: "path" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      placeholder: "页面菜单路径，如：/admin/user",
                    },
                    model: {
                      value: _vm.postForm.path,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "path", $$v)
                      },
                      expression: "postForm.path",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "视图组件", prop: "component" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.postForm.parentId === "0",
                      size: "small",
                      placeholder: "位于前端view/目录下的相对文件路径",
                    },
                    model: {
                      value: _vm.postForm.component,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "component", $$v)
                      },
                      expression: "postForm.component",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "权限标识" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      placeholder: "用于后端权限控制，如：sys:user:add",
                    },
                    model: {
                      value: _vm.postForm.permissionTag,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "permissionTag", $$v)
                      },
                      expression: "postForm.permissionTag",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "菜单图标" } }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _vm.postForm.metaIcon
                      ? _c("svg-icon", {
                          staticStyle: {
                            width: "20px",
                            height: "20px",
                            "margin-right": "10px",
                          },
                          attrs: { "icon-class": _vm.postForm.metaIcon },
                        })
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            _vm.iconVisible = true
                          },
                        },
                      },
                      [_vm._v("选择图标")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "mini" },
                        on: { click: _vm.clearIcon },
                      },
                      [_vm._v("清除")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "可视状态" } },
                [
                  _c("el-switch", {
                    attrs: { "active-text": "隐藏", "inactive-text": "显示" },
                    model: {
                      value: _vm.postForm.hidden,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "hidden", $$v)
                      },
                      expression: "postForm.hidden",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("icon-dialog", {
        attrs: { visible: _vm.iconVisible },
        on: {
          "update:visible": function ($event) {
            _vm.iconVisible = $event
          },
          select: _vm.iconSelect,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }