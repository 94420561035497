<template>
  <div>
    <div v-for="(item, index) in listData" :key="index" class="bind-items">
      <div class="tt">{{ item.loginType_dictText }}</div>
      <div v-if="item.openId" class="bind-content">
        <div class="bind-show">已绑定</div>
        <div><el-button type="danger" size="mini" @click="handleUnbind(item)">解绑</el-button></div>
      </div>
      <div v-else class="bind-content">
        <el-button type="primary" size="mini" @click="handleBind(item)">绑定</el-button>
      </div>
    </div>

    <bind-mobile
      :visible.sync="bindVisible"
      :type="postForm.openId ? 0 : 1"
      :mobile="postForm.openId"
      @success="fetchList"
    />
  </div>
</template>

<script>
import { bindList, unbind } from '@/api/sys/user/bind'
import { apiGetCropWechatUrl, apiGetDingUrl, apiGetWechatUrl } from '@/api/ability/login'
import BindMobile from '@/views/web/ucenter/components/BindMobile/index.vue'

export default {
  components: { BindMobile },
  data() {
    return {
      listData: [],

      // 默认绑定列表
      listMap: {
        mobile: { loginType: 'mobile', loginType_dictText: '手机登录' },
        wechat: { loginType: 'wechat', loginType_dictText: '微信登录' },
        'crop-wechat': { loginType: 'crop-wechat', loginType_dictText: '企业微信' },
        'ding-talk': { loginType: 'ding-talk', loginType_dictText: '钉钉登录' },
      },

      bindVisible: false,
      postForm: {
        openId: null,
      },
    }
  },

  watch: {},

  created() {
    this.fetchList()
  },

  methods: {
    // 绑定微信
    bindWechat() {
      // 获得跳转地址并跳转
      apiGetWechatUrl({ state: 'bind' }).then((res) => {
        window.location = res.data.url
      })
    },

    // 绑定企业微信
    bindCropWechat() {
      // 获得跳转地址并跳转
      apiGetCropWechatUrl({ state: 'bind' }).then((res) => {
        window.location = res.data.url
      })
    },

    bindDing() {
      // 获得跳转地址并跳转
      apiGetDingUrl({ state: 'bind' }).then((res) => {
        window.location = res.data.url
      })
    },

    // 绑定手机
    fetchList() {
      // 清理
      this.listData = []
      // 转换Map
      const listMap = new Map(Object.entries(this.listMap))

      bindList()
        .then((res) => {
          const list = res.data
          for (let i = 0; i < list.length; i++) {
            const item = list[i]
            // 进行覆盖
            listMap.set(item.loginType, item)
          }

          listMap.forEach((value) => {
            this.listData.push(value)
          })
        })
        .catch(() => {
          listMap.forEach((value) => {
            this.listData.push(value)
          })
        })
    },

    handleUnbind(item) {
      this.postForm = item
      if (item.loginType === 'mobile') {
        this.bindVisible = true
        return
      }

      // 删除
      this.$confirm('确定要解除绑定吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        unbind({ loginType: item.loginType }).then((res) => {
          this.$message.success('解绑成功！')
          this.fetchList()
        })
      })
    },

    handleBind(item) {
      console.log('tt', item.loginType)

      this.postForm = item
      if (item.loginType === 'mobile') {
        this.bindVisible = true
      }

      // 微信绑定
      if (item.loginType === 'wechat') {
        this.bindWechat()
      }

      // 企业微信
      if (item.loginType === 'crop-wechat') {
        this.bindCropWechat()
      }

      // 钉钉登录
      if (item.loginType === 'ding-talk') {
        this.bindDing()
      }
    },
  },
}
</script>

<style scoped>
.bind-items {
  display: flex;
  align-items: center;
  border-bottom: #eee 1px solid;
  padding: 10px;
  width: 100%;
}

.bind-items .tt {
  font-weight: 700;
  color: #555;
}

.bind-items .bind-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
}

.bind-items .bind-show {
  padding-right: 10px;
  color: #666;
  font-size: 14px;
}
</style>
