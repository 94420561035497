<template>
  <web-layout>
    <template #main>
      <div>
        <user-banner />
      </div>
      <div class="uc-box">
        <div class="uc-menu">
          <el-menu
            :default-active="active"
            :default-openeds="['exam']"
            style="width: 200px"
            background-color="#f8f8f8"
            unique-opened
            router
          >
            <el-menu-item index="/pages/uc/info"> <i class="el-icon-user" /> 个人资料 </el-menu-item>
            <el-menu-item index="/pages/uc/pass"> <i class="el-icon-lock" /> 密码安全 </el-menu-item>
            <el-menu-item index="/pages/uc/bind"> <i class="el-icon-connection" /> 账号绑定 </el-menu-item>
            <el-menu-item v-if="siteData.props.realPerson" index="/pages/uc/real">
              <i class="el-icon-bank-card" /> 实名认证
            </el-menu-item>
            <el-menu-item index="/pages/uc/im"> <i class="el-icon-message" /> 站内信息 </el-menu-item>
            <el-menu-item index="/pages/uc/points"> <i class="el-icon-coin" /> 积分日志 </el-menu-item>
          </el-menu>
        </div>
        <div class="uc-main">
          <app-main />
        </div>
      </div>
    </template>
  </web-layout>
</template>
<script>
import { mapGetters } from 'vuex'
import AppMain from '@/layout/components/AppMain.vue'
// import { WebHeader } from '@/layout/components'
import WebLayout from '@/layout/WebLayout.vue'
import UserBanner from '@/views/web/ucenter/components/UserBanner/index.vue'

export default {
  name: 'UserLayout',
  components: {
    UserBanner,
    WebLayout,
    // WebHeader,
    AppMain,
  },
  data() {
    return {
      active: 'exam',
      state: '',
    }
  },
  computed: {
    ...mapGetters(['siteData', 'token', 'realName']),
  },
  watch: {
    $route: {
      handler() {
        // this.$nextTick(() => {
        //   scrollTo(80, 300)
        // })
      },
    },
  },
  created() {
    this.active = this.$route.path
  },
}
</script>
<style scoped>
::v-deep .el-menu {
  border-right: none;
}

::v-deep .uc-menu .el-menu-item,
::v-deep .uc-menu .el-submenu__title {
  line-height: 46px;
  height: 46px;
}
</style>
