var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "result-box" }, [
    _c("div", { staticClass: "score" }, [_vm._v("考试结束")]),
    _c("div", [_vm._v(" " + _vm._s(_vm.data.thanks) + " ")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }