export default {
  install(Vue, options) {
    Vue.prototype.$navBack = function () {
      window.history.back()
    }

    // 演示环境
    Vue.prototype.$isDemo = process.env.VUE_APP_ENV === 'demo'

    // 开发环境
    Vue.prototype.$isDev = process.env.VUE_APP_ENV === 'development'
  },
}
