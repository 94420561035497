<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :append-to-body="true"
    title="设备调试"
    width="600px"
    @close="handleClose"
  >
    <div>
      <div>
        <tracking-cam :tracking="false" @started="camSuccess = true" @error="camSuccess = false" />
      </div>
      <div class="tips-lg" @click="handleClose">能看到视频图像则表示摄像头正常，点此关闭</div>
      <div class="tips-sm">
        <div>1、网页必须基于https下才能调用摄像头和麦克风设备。</div>
        <div>2、推荐使用Chrome谷歌浏览器、Firefox火狐浏览器。</div>
        <div>3、地址栏若弹出询问是否允许摄像头/麦克风时，必须允许。</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'SimpleDeviceTest',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      camSuccess: false,
    }
  },

  watch: {
    // 检测查询变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      },
      deep: true,
    },
  },

  created() {
    this.dialogVisible = this.visible
  },

  methods: {
    handleClose() {
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style scoped>
.tips-lg {
  background-color: #4377fb;
  color: #fff;
  text-align: center;
  font-weight: 700;
  padding: 10px 0;
  margin-top: 20px;
  font-size: 16px;
  cursor: pointer;
}

.tips-sm {
  line-height: 22px;
  margin-top: 10px;
  color: #888;
  font-size: 12px;
}
</style>
