<template>
  <div>
    <div style="background: #f5f5f5; padding: 10px; border-radius: 4px">
      <div style="padding-bottom: 5px">
        <el-checkbox v-model="strictly">级联选择下级</el-checkbox>
      </div>
      <el-input v-model="filterText" placeholder="输入关键字进行过滤" size="small" />
    </div>

    <el-tree
      v-loading="treeLoading"
      ref="tree"
      :data="treeData"
      :check-strictly="!strictly"
      :default-checked-keys="deptCodes"
      :props="defaultProps"
      :filter-node-method="filterNode"
      style="margin-top: 10px"
      empty-text=" "
      default-expand-all
      show-checkbox
      node-key="deptCode"
      @check-change="handleCheckChange"
    />
  </div>
</template>

<script>
import { fetchTree } from '@/api/sys/depart/depart'
export default {
  name: 'DepartRefs',
  props: {
    value: Array,
  },
  data() {
    return {
      strictly: false,
      defaultProps: {
        label: 'deptName',
      },
      filterText: '',
      treeLoading: false,
      deptCodes: [],
      treeData: [],
    }
  },

  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },

    value(val) {
      this.deptCodes = val
    },
  },
  created() {
    // 初始化赋值
    this.deptCodes = this.value

    fetchTree({}).then((response) => {
      this.treeData = response.data
    })
  },
  methods: {
    handleCheckChange() {
      // 置空
      const deptCodes = []

      const nodes = this.$refs.tree.getCheckedNodes()
      nodes.forEach(function (item) {
        deptCodes.push(item.deptCode)
      })

      this.$emit('input', deptCodes)
    },

    filterNode(value, data) {
      if (!value) return true
      return data.deptName.indexOf(value) !== -1
    },
  },
}
</script>
