<template>
  <div :style="{ width: editorWidth }" class="tinymce-container">
    <textarea :id="tinymceId" class="tinymce-textarea" />
    <editor-attach-dialog :visible.sync="attachVisible" @uploaded="handleAttr" />
  </div>
</template>

<script>
import plugins from './plugins'
import toolbar from './toolbar'
import { uploadFile } from '@/utils/upload'
import EditorAttachDialog from '@/components/Tinymce/components/EditorAttachDialog.vue'

export default {
  name: 'Tinymce',
  components: { EditorAttachDialog },
  props: {
    id: {
      type: String,
      default: function () {
        return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
      },
    },
    value: {
      type: String,
      default: '',
    },
    toolbar: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    height: {
      type: [Number, String],
      required: false,
      default: 360,
    },
    width: {
      type: [Number, String],
      required: false,
      default: 'auto',
    },
    paste: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      attachVisible: false,
      tinymceId: this.id,
      inputChange: false,
      lastKeyupTime: null,
    }
  },
  computed: {
    editorWidth() {
      const width = this.width
      if (/^[\d]+(\.[\d]+)?$/.test(width)) {
        // matches `100`, `'100'`
        return `${width}px`
      }
      return width
    },
  },
  watch: {
    value: {
      handler() {
        this.fillContent()
      },
      deep: true,
    },
  },
  mounted() {
    // 初始化并充数据，一般为静态内容
    this.initTinymce((editor) => {
      editor.setContent(this.value)
    })
  },

  destroyed() {
    this.destroyTinymce()
  },
  methods: {
    // 用于初始化内容
    fillContent() {
      if (!this.inputChange) {
        this.$nextTick(() => {
          window.tinymce.get(this.tinymceId).setContent(this.value || '')
        })
      }
    },

    // 初始化
    initTinymce(callback) {
      const that = this
      window.tinymce.init({
        selector: `#${this.tinymceId}`,
        convert_urls: false,
        language: 'zh_CN',
        height: this.height,
        body_class: 'panel-body ',
        object_resizing: false,
        toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
        menubar: false,
        statusbar: false,
        branding: false,
        media_live_embeds: true,
        plugins: plugins,
        end_container_on_empty_block: true,
        powerpaste_word_import: 'propmt',
        powerpaste_html_import: 'propmt',
        powerpaste_allow_local_images: true,
        paste_data_images: true,
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        default_link_target: '_blank',
        link_title: false,
        wirisformulaeditorlang: 'zh',
        nonbreaking_force_tab: true,
        init_instance_callback: (editor) => {
          // 初始化后回调
          if (callback) {
            callback(editor)
          }

          editor.on('KeyUp', (e) => {
            that.lastKeyupTime = e.timeStamp
            // 输入停下2秒后保存
            setTimeout(() => {
              if (that.lastKeyupTime === e.timeStamp) {
                that.$emit('blur', editor.getContent())
              }
            }, 2000)
          })

          editor.on('NodeChange Change KeyUp SetContent', (e) => {
            // 有输入就触发
            that.$emit('input', editor.getContent())
          })

          // 失去焦点
          editor.on('blur', () => {
            that.$emit('blur', editor.getContent())
          })

          // 获得焦点
          editor.on('focus', () => {
            that.$emit('focus')
            this.inputChange = true
          })
        },
        // 屏蔽下载功能
        extended_valid_elements:
          'video[src|controlslist|controls|width|height],audio[src|controlslist|controls|width|height]',
        setup(editor) {
          editor.ui.registry.addButton('attach', {
            // text：工具栏的显示的内容
            text: '<i class="el-icon-paperclip" style="font-size:22px"></i>',
            onAction: () => {
              that.inputChange = true
              that.attachVisible = true
            },
          })
        },
        // 禁用粘贴
        paste_preprocess: function (plugin, args) {
          if (!that.paste) {
            that.$message.info('考试期间不能复制粘贴哦！')
            args.stopImmediatePropagation()
            args.stopPropagation()
            args.preventDefault()
            args.content = ''
          }
        },
        // 粘贴文件上传
        images_upload_handler: function (blobInfo, success, failure) {
          const file = blobInfo.blob()
          uploadFile(file)
            .then((url) => {
              // 回写URL地址
              success(url)
            })
            .catch(() => {
              failure()
            })
        },
      })
    },
    destroyTinymce() {
      const tinymce = window.tinymce.get(this.tinymceId)
      if (tinymce) {
        tinymce.destroy()
      }
      // window.tinymce.remove(this.tinymceId)
    },
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value)
    },
    getContent() {
      window.tinymce.get(this.tinymceId).getContent()
    },
    handleAttr(data) {
      const that = this

      const fileList = data.fileList
      const mediaType = data.mediaType

      // 插入内容
      let html = ''

      // 图片
      if (mediaType === 1) {
        for (let i = 0; i < fileList.length; i++) {
          html += `<p><img class="wscnph" src="${fileList[i].url}" /></p>`
        }
      }

      // 视频
      if (mediaType === 2) {
        for (let i = 0; i < fileList.length; i++) {
          html += `<p><video src="${fileList[i].url}" controls="controls" controlslist="nodownload" width="600px" height="auto" oncontextmenu="return false"></video></p>`
        }
      }

      // 音频
      if (mediaType === 3 || mediaType === 4) {
        for (let i = 0; i < fileList.length; i++) {
          html += `<p><audio src="${fileList[i].url}" controls="controls" controlslist="nodownload" oncontextmenu="return false"></audio></p>`
        }
      }

      // 文件
      if (mediaType === 5) {
        for (let i = 0; i < fileList.length; i++) {
          html += `<p><a href="${fileList[i].url}" target="_blank">${fileList[i].url}</a></p>`
        }
      }

      window.tinymce.get(that.tinymceId).insertContent(html)
    },
  },
}
</script>

<style scoped>
.tinymce-container {
  position: relative;
  line-height: normal;
}
::v-deep .tox .tox-tbtn--bespoke .tox-tbtn__select-label {
  width: 30px;
}
</style>
