<template>
  <el-date-picker
    v-model="dateRange"
    :picker-options="pickerOptions"
    size="small"
    format="yyyy-MM-dd HH:mm:ss"
    value-format="yyyy-MM-dd HH:mm:ss"
    type="datetimerange"
    range-separator="至"
    start-placeholder="开始时间"
    end-placeholder="结束时间"
  />
</template>
<script>
export default {
  name: 'DateTimePicker',
  props: {
    // 接收绑定参数
    startTime: String,
    // 输入框宽度
    endTime: String,
    // 限制时间段，时间戳格式getTime获得
    minDate: Date,
    maxDate: Date,
  },
  data() {
    return {
      dateRange: [],
      pickerOptions: {
        disabledDate: (time) => {
          let min = 0
          let max = 0

          if (this.minDate != null) {
            min = this.minDate.getTime()
          }
          if (this.maxDate != null) {
            max = this.maxDate.getTime()
          }

          if (min > 0 && max === 0) {
            return time.getTime() <= min
          }

          if (min === 0 && max > 0) {
            return time.getTime() >= max
          }

          if (min > 0 && max > 0) {
            return time.getTime() <= min && time.getTime() >= max
          }

          return false
        },
      },
    }
  },
  watch: {
    startTime() {
      this.fillData()
    },
    endTime() {
      this.fillData()
    },
    dateRange(val) {
      if (val && val.length === 2) {
        this.$emit('update:startTime', val[0])
        this.$emit('update:endTime', val[1])
      } else {
        this.$emit('update:startTime', '')
        this.$emit('update:endTime', '')
      }
    },
  },
  created() {
    this.fillData()
  },
  methods: {
    fillData() {
      if (this.startTime && this.endTime) {
        this.dateRange = [this.startTime, this.endTime]
      }
    },
  },
}
</script>
