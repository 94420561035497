<template>
  <div v-if="quData && quData.id" :class="{ 'sub-box': sub }" :id="`qu-${quData.quId}`" class="qu-box">
    <div :class="{ 'pin-class': pin && quData.quType === '99' }">
      <div v-if="showType" class="score-bar">
        <div style="flex-grow: 1">{{ quData.quType_dictText }} （本题分值：{{ quData.score }}分）</div>

        <div v-if="quData.quType !== '99'" class="mark">
          <el-tooltip
            v-if="mark"
            :content="quData.mark ? '取消本题的疑难标记' : '为本题添加疑难标记'"
            effect="dark"
            placement="bottom"
          >
            <i :class="{ 'el-icon-s-flag': true, active: quData.mark }" style="cursor: pointer" @click="markQu()" />
          </el-tooltip>
        </div>
        <div v-else style="display: flex; align-items: center">
          <i
            :class="{ 'el-icon-paperclip': true, 'pin-btn': true, 'is-pin': pin }"
            style="cursor: pointer"
            @click="doPin()"
          />
        </div>
      </div>

      <div class="qu-item">
        <div :class="sub ? 'sub' : 'num'">
          <span v-if="sort">{{ sort }}.</span>
          {{ quData.sort }}
        </div>
        <div class="content">
          <div v-html="$options.filters.formatRichText(quData.content)" />
        </div>
      </div>

      <!-- 单选多选判断 -->
      <div v-if="quData.quType === '1' || quData.quType === '2' || quData.quType === '3'" class="qu-answer">
        <div
          v-for="item in quData.answerList"
          :key="item.id"
          :class="{ active: item.checked }"
          class="item"
          @click="itemClick(item)"
        >
          <div class="tag">{{ item.abc }}</div>
          <div class="content">
            <div>{{ item.content }}</div>
            <div v-if="item.image">
              <img :src="item.image" style="max-width: 90%" alt="" />
            </div>
          </div>
        </div>
      </div>

      <!-- 主观题 -->
      <div v-if="quData.quType === '4'">
        <div class="as-box">
          <Tinymce
            ref="tinymce"
            :paste="paste"
            :id="quData.id"
            v-model="quData.answer"
            style="width: 100%"
            @blur="tinyInput"
            @focus="inputFocus"
            @input="handleAction"
          />
        </div>
      </div>

      <!-- 填空题 -->
      <div v-if="quData.quType === '5'" class="qu-answer">
        <div v-for="item in quData.answerList" :key="item.id" class="item">
          <div class="content">
            <el-input v-model="item.answer" @change="handleSave" @focus="inputFocus" />
          </div>
        </div>
      </div>

      <div v-if="sub" class="split" />
    </div>

    <!-- 组合题引用自己 -->
    <div v-if="quData.quType === '99'" :style="`margin-top: ${subMargin}px`">
      <div v-for="sub in quData.subList" :key="sub.id">
        <qu-item-exam
          :key="sub.id"
          :show-type="showType"
          :sort="quData.sort"
          :value="sub"
          :sub="true"
          @focus="subFocus"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuItemExam',
  components: {},
  props: {
    value: {
      type: Object,
      default: function () {
        return {}
      },
    },
    showType: {
      type: Boolean,
      default: function () {
        return true
      },
    },
    mark: {
      type: Boolean,
      default: function () {
        return true
      },
    },
    sub: {
      type: Boolean,
      default: function () {
        return false
      },
    },
    sort: {
      type: Number,
      default: function () {
        return null
      },
    },
  },

  data() {
    return {
      // 开发环境允许富文本粘贴
      paste: this.$isDev,
      // 初始化
      init: false,
      // 编辑器原始内容
      content: '',
      // 当前试题内容
      quData: {
        quType: '',
        answer: '',
        answerList: [],
      },
      pin: false,
      subMargin: 0,
    }
  },

  watch: {
    // 检测查询变化
    value: {
      handler(val) {
        this.fillData(val)
      },
    },
    quData: {
      handler() {
        // 组合题从上层保存
        if (!this.sub && this.quData.quType === '99') {
          this.handleSave()
        }
      },
      deep: true,
    },
  },

  created() {
    this.fillData(this.value)
  },

  methods: {
    // 填充数据
    fillData(data) {
      if (!data) {
        return
      }

      this.quData = data
      // 处理空白状态
      if (this.quData.quType === '4' && this.$refs.tinymce) {
        this.content = this.quData.answer || ''
        this.$refs.tinymce.setContent(this.content)
      }

      // 题目初始化状态
      this.$nextTick(() => {
        setTimeout(() => {
          this.init = true
        }, 100)
      })
    },

    // 编辑器内容改变
    tinyInput(content) {
      this.quData.answer = content
      this.handleSave()
    },

    // 填空题开始输入
    inputFocus() {
      this.$emit('focus', this.quData.quId, 'empty')
    },

    // 子题目获得焦点
    subFocus(subId) {
      this.$emit('focus', this.quData.quId, subId)
    },

    // 选定值
    itemClick(item) {
      item.checked = !item.checked
      // 当前题目
      this.inputFocus()

      // 单选要清理其它数据
      if (this.quData.quType === '1' || this.quData.quType === '3') {
        this.quData.answerList.forEach(function (qu) {
          console.log(qu)
          if (item.id !== qu.id) {
            qu.checked = false
          }
        })
      }

      this.handleSave()
    },

    // 处理
    markQu() {
      this.quData.mark = !this.quData.mark
      this.handleSave()
    },

    doPin() {
      this.pin = !this.pin
      if (this.pin) {
        this.$nextTick(() => {
          const dom = document.querySelector('.pin-class')
          this.subMargin = dom.clientHeight + 20
        })
      } else {
        this.subMargin = 0
      }
    },

    // 保存答案
    handleSave() {
      // 子题目
      if (this.sub || !this.init) {
        return
      }
      this.$emit('fill', this.quData)
    },
    handleAction() {
      this.$emit('action')
    },
  },
}
</script>

<style scoped>
.pin-class {
  position: fixed;
  top: 40px;
  background: #fff;
  border: #5794f7 2px dashed;
  width: calc(70vw - 60px);
  z-index: 999;
  max-height: 35vh;
  overflow-y: auto;
}

.pin-btn {
  color: #555;
  font-weight: 700;
  font-size: 18px;
  margin-right: 10px;
}

.is-pin {
  color: #5794f7;
}
</style>
