<template>
  <div>
    <div v-for="item in postForm.groupList" :id="item.id" :key="item.id">
      <el-card v-for="(qu, index) in item.quList" :id="qu.id" :key="qu.id" class="content-card">
        <qu-item-show
          :value="qu"
          :index="index"
          :show-type="true"
          :show-score="true"
          :review-state="postForm.reviewState"
        />

        <!-- 组合题子题目列表 -->
        <div v-if="qu.quType === '99'">
          <div v-for="(sub, jj) in qu.subList" :key="sub.id">
            <qu-item-show
              :value="sub"
              :index="jj"
              :review-state="postForm.reviewState"
              :show-type="true"
              :show-score="true"
              :sub="true"
            />
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import QuItemShow from '@/views/admin/repo/qu/components/QuItemShow.vue'
export default {
  name: 'ResultQuList',
  components: { QuItemShow },
  props: {
    data: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      quType: null,
      postForm: {
        groupList: [],
      },
    }
  },
  created() {
    this.postForm = this.data
  },
  methods: {
    hasLink(id) {
      return false
    },

    userAnswer(answerList) {
      let answer = ''

      for (let i = 0; i < answerList.length; i++) {
        if (answerList[i].checked) {
          answer += answerList[i].abc
        }
      }

      if (answer == '') {
        answer = '未作答'
      }

      return answer
    },
  },
}
</script>

<style scoped>
.content-card {
  margin-bottom: 20px;
  line-height: 28px;
}
</style>
