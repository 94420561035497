var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { attrs: { gutter: 20 } },
    [
      _c(
        "el-col",
        { attrs: { span: 16 } },
        [
          _c(
            "el-card",
            [
              _c("web-table", {
                ref: "pagingTable",
                attrs: { options: _vm.options, "list-query": _vm.listQuery },
                on: { "select-changed": _vm.handleSelect },
                scopedSlots: _vm._u([
                  {
                    key: "filter-content",
                    fn: function () {
                      return [
                        _c("div", { staticStyle: { display: "flex" } }, [
                          _c(
                            "div",
                            { staticStyle: { "flex-grow": "1" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "搜索错题",
                                  "prefix-icon": "el-icon-search",
                                },
                                model: {
                                  value: _vm.listQuery.params.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery.params, "title", $$v)
                                  },
                                  expression: "listQuery.params.title",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-content": "flex-end",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-s-promotion",
                                  },
                                  on: { click: _vm.startTrain },
                                },
                                [_vm._v(" 错题训练 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: _vm.selections.length === 0,
                                    type: "danger",
                                    icon: "el-icon-delete",
                                  },
                                  on: { click: _vm.handelDelete },
                                },
                                [_vm._v(" 删除 ")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "data-columns",
                    fn: function () {
                      return [
                        _c("el-table-column", {
                          attrs: {
                            label: "题目",
                            prop: "title",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("detail-link", {
                                    attrs: {
                                      id: scope.row.quId,
                                      title: scope.row.title,
                                      to: "ViewQu",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "题型",
                            prop: "quType_dictText",
                            align: "center",
                            width: "100px",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "错误次数",
                            prop: "wrongCount",
                            align: "center",
                            width: "100px",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "加入时间",
                            prop: "wrongTime",
                            align: "center",
                            width: "180px",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 8 } },
        [
          _c(
            "el-card",
            [
              _c("h3", [_vm._v("最近训练")]),
              _vm.latestList.length === 0
                ? _c("div", [_vm._v("您还没有训练记录！")])
                : _vm._e(),
              _vm._l(_vm.latestList, function (item) {
                return _c(
                  "el-row",
                  { key: item.id, staticClass: "his-item" },
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _vm._v(_vm._s(item.updateTime)),
                    ]),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        item.state === 0
                          ? _c(
                              "el-link",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-video-play",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.conTrain(
                                      item.repoId,
                                      item.trainMode
                                    )
                                  },
                                },
                              },
                              [_vm._v("继续训练")]
                            )
                          : _c(
                              "el-link",
                              {
                                attrs: {
                                  icon: "el-icon-video-play",
                                  disabled: "",
                                },
                              },
                              [_vm._v("训练结束")]
                            ),
                      ],
                      1
                    ),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _vm._v(
                        "进度：" +
                          _vm._s(item.answerCount) +
                          "/" +
                          _vm._s(item.totalCount)
                      ),
                    ]),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _vm._v("正确率：" + _vm._s(item.percent) + "%"),
                    ]),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }