var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticClass: "guide", attrs: { id: "dash-opt-box", gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c("h3", [_vm._v("快捷入口")]),
          ]),
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["exam:add"],
                  expression: "['exam:add']",
                },
              ],
              staticClass: "dash-item module-exam",
              attrs: { span: _vm.span1 },
            },
            [
              _c("el-card", [
                _c(
                  "div",
                  { staticClass: "opt-item", on: { click: _vm.navToAddExam } },
                  [
                    _c("div", { staticClass: "opt-img" }, [
                      _c("img", {
                        attrs: { src: require("@/assets/dash/a1.png") },
                      }),
                    ]),
                    _c("div", { staticClass: "opt-content" }, [
                      _c("div", [_vm._v("创建考试")]),
                      _c("div", [_vm._v("快速创建一场考试，支持多种选题方式")]),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["repo:list"],
                  expression: "['repo:list']",
                },
              ],
              staticClass: "dash-item module-exam",
              attrs: { span: _vm.span1 },
            },
            [
              _c("el-card", [
                _c(
                  "div",
                  { staticClass: "opt-item", on: { click: _vm.navToRepoList } },
                  [
                    _c("div", { staticClass: "opt-img" }, [
                      _c("img", {
                        attrs: { src: require("@/assets/dash/a3.png") },
                      }),
                    ]),
                    _c("div", { staticClass: "opt-content" }, [
                      _c("div", [_vm._v("题库管理")]),
                      _c("div", [
                        _vm._v("批量导入导出、设定章节、题目难易程度"),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
          _vm.showNotice
            ? _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["sys:notice:list"],
                      expression: "['sys:notice:list']",
                    },
                  ],
                  staticClass: "dash-item",
                  attrs: { span: _vm.span1 },
                },
                [
                  _c("el-card", [
                    _c(
                      "div",
                      {
                        staticClass: "opt-item",
                        on: { click: _vm.navToNotice },
                      },
                      [
                        _c("div", { staticClass: "opt-img" }, [
                          _c("img", {
                            attrs: { src: require("@/assets/dash/a6.png") },
                          }),
                        ]),
                        _c("div", { staticClass: "opt-content" }, [
                          _c("div", [_vm._v("公告管理")]),
                          _c("div", [_vm._v("发布日常公告、新闻及提醒事项")]),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }