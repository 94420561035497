<template>
  <div>
    <el-form ref="postForm" :model="postForm" :rules="rules" label-position="left" label-width="150px">
      <el-form-item label="新用户注册">
        <el-switch
          v-model="postForm.userReg"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="开启"
          inactive-text="关闭"
        />
      </el-form-item>

      <el-form-item v-if="postForm.userReg" label="新用户审核">
        <el-switch
          v-model="postForm.userAudit"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="开启"
          inactive-text="关闭"
        />
        <small style="margin-left: 20px; color: #888">新用户注册后，需要通过审核才能登录系统</small>
      </el-form-item>

      <el-form-item v-if="postForm.userReg" label="新用户部门">
        <el-radio-group v-model="postForm.userDeptType" size="small">
          <el-radio :label="0">用户自由选择</el-radio>
          <el-radio :label="1">指定默认部门</el-radio>
        </el-radio-group>

        <div v-if="postForm.userDeptType === 1" style="width: 400px">
          <depart-tree-select v-model="postForm.userDeptCode" />
        </div>

        <span v-if="postForm.userDeptType === 0">
          <small style="color: #888; margin-left: 20px">注册时，用户可自由选择归属部门</small>
        </span>
      </el-form-item>

      <el-form-item label="用户登录方式">
        <el-checkbox v-model="postForm.mobileLogin">手机号登录</el-checkbox>
        <el-checkbox v-model="postForm.wechatLogin">微信登录</el-checkbox>
        <el-checkbox v-model="postForm.faceLogin">人脸登录</el-checkbox>
        <el-checkbox v-model="postForm.cropLogin">企业微信登录</el-checkbox>
        <el-checkbox v-model="postForm.dingLogin">钉钉登录</el-checkbox>
      </el-form-item>

      <el-form-item label="实名认证">
        <el-switch
          v-model="postForm.realPerson"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="开启"
          inactive-text="关闭"
        />
      </el-form-item>

      <el-form-item v-if="postForm.realPerson" label="实名审核">
        <el-radio-group v-model="postForm.faceAudit" size="small">
          <el-radio label="no">无需审核</el-radio>
          <el-radio label="manual">人工审核</el-radio>
          <el-radio label="auto">人工审核+自动辅助</el-radio>
        </el-radio-group>

        <el-alert
          v-if="postForm.faceAudit === 'auto'"
          style="line-height: normal; margin-top: 10px"
          type="error"
          title="开启一键审核功能，由【人脸识别】中的服务商提供，自动校验姓名、身份证号、人脸照片是否匹配；请确保开通了人脸实名认证功能，并注意费用消耗。"
        />
      </el-form-item>

      <el-form-item label="允许多处登录">
        <el-radio v-model="postForm.loginTick" :label="0">允许多处登录</el-radio>
        <el-radio v-model="postForm.loginTick" :label="1">将前面的账号踢下线</el-radio>
      </el-form-item>
    </el-form>

    <el-divider />

    <div style="margin-top: 20px">
      <el-button size="small" type="primary" @click="submitForm">保存配置</el-button>
    </div>
  </div>
</template>

<script>
import { fetchDetail, saveData } from '@/api/sys/config/switch'

export default {
  name: 'SwitchConfig',
  components: {},
  data() {
    return {
      postForm: {
        faceAudit: 0,
      },
      loading: false,
      rules: {},
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      fetchDetail().then((res) => {
        this.postForm = res.data
      })
    },
    submitForm() {
      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }

        this.loading = true

        saveData(this.postForm).then(() => {
          this.$notify({
            title: '成功',
            message: '功能开关配置成功！',
            type: 'success',
            duration: 2000,
          })
        })
        this.loading = false
      })
    },
  },
}
</script>

<style scoped></style>
