<template>
  <div class="app-container">
    <div class="filter-container" style="margin-bottom: 20px">
      <slot name="filter-content" />
    </div>

    <el-table
      v-loading="listLoading"
      ref="table"
      :data="dataList.records"
      :row-key="getRowKeys"
      :row-style="tableRowStyle"
      :header-cell-style="tableHeaderColor"
      fit
      highlight-current-row
      @sort-change="tableSortChange"
      @selection-change="handleSelection"
    >
      <template #empty>
        <el-empty :image-size="100" :description="options.noData" />
      </template>

      <el-table-column v-if="options.multi" :reserve-selection="false" align="center" type="selection" width="55" />

      <slot name="data-columns" />
    </el-table>

    <pagination
      v-show="dataList.total > 0"
      :total="dataList.total"
      :page.sync="listQuery.current"
      :limit.sync="listQuery.size"
      @pagination="getList"
    />
  </div>
</template>

<script>
import { postData } from '@/api/common'

export default {
  name: 'WebTable',
  components: {},
  // 组件入参
  props: {
    options: {
      type: Object,
      default: () => {
        return {
          keyId: 'id',
          add: {
            enable: false,
            permission: '',
            router: {},
          },
          edit: {
            enable: false,
            permission: '',
            router: {},
          },
          delete: {
            enable: false,
            permission: '',
            url: '',
          },
          // 批量操作
          multiActions: [],
          // 列表请求URL
          listUrl: '/api/',
          // 删除请求URL
          deleteUrl: '',
          // 启用禁用
          stateUrl: '',
          // 可批量操作
          multi: false,
          noData: '暂无数据！',
        }
      },
    },

    // 列表查询参数
    listQuery: {
      type: Object,
      default: () => {
        return {
          current: 1,
          size: 10,
          params: {},
          t: 0,
        }
      },
    },
  },
  data() {
    return {
      // 接口数据返回
      dataList: {
        total: 0,
      },
      // 数据加载标识
      listLoading: true,
      // 选定和批量操作
      selectedIds: [],
      selectedObjs: [],
      // 显示已中多少项
      selectedLabel: '',
      // 显示批量操作
      multiShow: false,
      // 批量操作的标识
      multiNow: '',
    }
  },
  watch: {
    // 检测查询变化
    listQuery: {
      handler() {
        this.getList()
      },
      deep: true,
    },
  },
  created() {
    this.getList()
  },
  methods: {
    // 获取选定的key
    getRowKeys(row) {
      if (this.options.keyId != null && this.options.keyId !== '') {
        return row[this.options.keyId]
      }
      return row['id']
    },

    /**
     * 查询数据列表
     */
    getList() {
      this.listLoading = true
      this.listQuery.t = new Date().getTime()
      postData(this.options.listUrl, this.listQuery).then((response) => {
        this.dataList = response.data
        this.listLoading = false
      })
    },

    /**
     * 列表多选操作
     * @param val
     */
    handleSelection(val) {
      const ids = []
      val.forEach((row) => {
        ids.push(row.id)
      })
      this.selectedObjs = val
      this.selectedIds = ids
      this.multiShow = ids.length > 0
      this.selectedLabel = '已选' + ids.length + '项'
      this.$emit('select-changed', { ids: this.selectedIds, objs: this.selectedObjs })
    },

    tableSortChange(column) {
      this.listQuery.pageNo = 1
      if (column.order === 'descending') {
        this.listQuery.orders = [{ column: column.prop, asc: false }]
      } else {
        this.listQuery.orders = [{ column: column.prop, asc: true }]
      }
      this.getList()
    },

    // 清理选择的
    clearSelection() {
      this.$refs.table.clearSelection()
    },

    // 设置表格行的样式
    tableRowStyle({ row, rowIndex }) {
      return 'background-color:#666;font-size:16px;'
    },
    // 设置表头行的样式
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      return 'background-color:#F7F9FE;color:#192437;font-wight:700;font-size:16px;'
    },
  },
}
</script>

<style scoped>
::v-deep .filter-container .filter-item {
  margin-left: 10px;
}

::v-deep .filter-container .filter-item:first-child {
  margin-left: 0;
}

::v-deep .el-table .cell {
  font-size: 14px;
  color: #606266;
}

::v-deep .el-table th.el-table__cell > .cell {
  padding: 10px 5px;
  font-size: 16px;
  color: #192437;
}
</style>
