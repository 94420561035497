var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "qu-box",
      class: { "sub-box": _vm.sub },
      attrs: { id: _vm.quData.id },
    },
    [
      _c("div", { staticClass: "qu-item" }, [
        _vm.showNum
          ? _c("div", { class: _vm.sub ? "sub" : "num" }, [
              _vm._v(_vm._s(_vm.quData.sort || _vm.no + 1 || _vm.index)),
            ])
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "content",
            staticStyle: {
              display: "flex",
              "flex-wrap": "wrap",
              "align-items": "center",
            },
          },
          [
            _vm.showType
              ? _c("span", { staticStyle: { color: "#0a84ff" } }, [
                  _vm._v("【" + _vm._s(_vm.quData.quType_dictText) + "】"),
                ])
              : _vm._e(),
            _c("span", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$options.filters.formatRichText(_vm.quData.content)
                ),
              },
            }),
            _vm.showScore
              ? _c("span", { staticStyle: { color: "#ff9800" } }, [
                  _vm._v("【" + _vm._s(_vm.quData.score) + "分】"),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _vm.quData.quType === "1" ||
      _vm.quData.quType === "2" ||
      _vm.quData.quType === "3" ||
      _vm.quData.quType === "5"
        ? _c(
            "div",
            { staticClass: "qu-answer" },
            _vm._l(_vm.quData.answerList, function (item) {
              return _c("div", { key: item.id, staticClass: "item" }, [
                _c("div", { staticClass: "tag" }, [
                  _vm._v(_vm._s(item.abc || item.tag)),
                ]),
                _vm.showAnswer || _vm.quData.quType !== "5"
                  ? _c("div", { staticClass: "content" }, [
                      _c("div", [_vm._v(_vm._s(item.content))]),
                      item.image
                        ? _c(
                            "div",
                            [
                              _c("el-image", {
                                staticStyle: {
                                  width: "120px",
                                  height: "120px",
                                },
                                attrs: {
                                  src: item.image,
                                  "preview-src-list": [item.image],
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                item.isRight && _vm.showAnswer
                  ? _c("div", { staticClass: "right" }, [
                      _c("i", {
                        staticClass: "el-icon-success",
                        staticStyle: { color: "#1aac1a" },
                      }),
                      _vm._v(" 答案 "),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          )
        : _vm._e(),
      _vm.quData.quType === "4"
        ? _c("div", [
            _c("div", { staticStyle: { "margin-top": "12px" } }, [
              _vm._v(" 参考答案："),
              _vm.quData.referenceAnswer
                ? _c("span", {
                    staticStyle: { margin: "0 !important" },
                    domProps: { innerHTML: _vm._s(_vm.quData.referenceAnswer) },
                  })
                : _c("span", [_vm._v("-")]),
            ]),
            _c("div", { staticStyle: { margin: "6px 0" } }, [
              _vm._v(" 答题要点："),
              _vm.quData.keyPoint
                ? _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.quData.keyPoint) },
                  })
                : _c("span", [_vm._v("-")]),
            ]),
            _c("div", [
              _vm._v(" 评分标准："),
              _vm.quData.scoringCriteria
                ? _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.quData.scoringCriteria) },
                  })
                : _c("span", [_vm._v("-")]),
            ]),
            _vm.showAnswer
              ? _c("div", [
                  _vm.quData.answerList && _vm.quData.answerList.length > 0
                    ? _c(
                        "div",
                        { staticClass: "qu-answer" },
                        [
                          _c("div", { staticClass: "show-t" }, [
                            _vm._v("给分关键词："),
                          ]),
                          _vm._l(_vm.quData.answerList, function (item) {
                            return _c(
                              "div",
                              { key: item.id, staticClass: "item" },
                              [
                                _c("div", { staticClass: "content" }, [
                                  _c("div", [_vm._v(_vm._s(item.content))]),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "right",
                                    staticStyle: { width: "120px" },
                                  },
                                  [
                                    _vm._v(
                                      "给分比例：" +
                                        _vm._s(item.scoreRate) +
                                        "%"
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _c("div", { staticStyle: { "line-height": "30px" } }, [
                    _c("span", { staticClass: "show-t" }, [
                      _vm._v("人工批阅："),
                    ]),
                    _vm.quData.manual
                      ? _c("span", { staticStyle: { color: "#ff3333" } }, [
                          _vm._v("是，交卷后需阅卷老师手动批改"),
                        ])
                      : _c("span", { staticStyle: { color: "#1aac1a" } }, [
                          _vm._v("否，交卷后自动根据关键字判分并累加成绩"),
                        ]),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.quData.quType === "5" && _vm.quData.answerOrder
        ? _c("div", [
            _c(
              "div",
              { staticStyle: { "line-height": "30px", color: "#ff3333" } },
              [_vm._v("此题需严格按顺序作答才可得分！")]
            ),
          ])
        : _vm._e(),
      _vm.quData.quType === "6" && _vm.quData.tableList
        ? _c(
            "div",
            [
              _c("div", [_vm._v("配分及评分要求：")]),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%", "margin-top": "12px" },
                  attrs: { data: _vm.quData.tableList, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "序号", width: "60", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [_vm._v(" " + _vm._s(scope.$index + 1))]
                          },
                        },
                      ],
                      null,
                      false,
                      2198601330
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "assessmentContent",
                      align: "center",
                      label: "考核内容",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "assessmentCore",
                      align: "center",
                      label: "考核要点",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "partitionScore",
                      align: "center",
                      label: "配分",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "standard",
                      align: "center",
                      label: "评分配置",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showAnalysis &&
      ((_vm.mode !== 3 && _vm.quData.quType !== "99") || _vm.quData.analysis)
        ? _c("div", { staticClass: "as-box" }, [
            _vm.mode !== 3 && _vm.quData.quType !== "99"
              ? _c("div", [
                  _vm.quData.quType !== "4" && _vm.quData.quType !== "99"
                    ? _c("div", [
                        _c("div", { staticClass: "right-line" }, [
                          _c("i", {
                            class: _vm.quData.isRight
                              ? "right el-icon-success"
                              : "error el-icon-error",
                          }),
                          _vm.quData.isRight
                            ? _c("span", [_vm._v("答对了")])
                            : _c("span", [_vm._v("答错了")]),
                        ]),
                      ])
                    : _vm._e(),
                  _c("div", [
                    _vm._v(" 学员得分： "),
                    _vm.quData.quType === "4" && _vm.reviewState === 0
                      ? _c("span", [_vm._v(" 待阅卷后显示得分 ")])
                      : _c("span", [
                          _vm._v(" " + _vm._s(_vm.quData.actualScore) + " "),
                          !_vm.quData.isRight &&
                          _vm.quData.actualScore > 0 &&
                          _vm.quData.actualScore !== _vm.quData.score
                            ? _c("span", [_vm._v("（部分得分）")])
                            : _vm._e(),
                        ]),
                  ]),
                  _vm.quData.quType === "1" ||
                  _vm.quData.quType === "2" ||
                  _vm.quData.quType === "3"
                    ? _c("div", [
                        _vm._v(" 学员答案： "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.userAnswer(_vm.quData.answerList))),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.quData.quType === "4"
                    ? _c("div", [
                        _vm._v(" 学员答案： "),
                        _c("div", {
                          staticClass: "asp",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.highlight(
                                _vm.quData.answer,
                                _vm.quData.answerList
                              )
                            ),
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm.quData.media != null && _vm.quData.media !== ""
                    ? _c("div", [
                        _c("audio", {
                          attrs: { src: _vm.quData.media, controls: "" },
                        }),
                      ])
                    : _vm._e(),
                  _vm.quData.quType === "5"
                    ? _c(
                        "div",
                        [
                          _vm._v(" 学员答案： "),
                          _vm._l(_vm.quData.answerList, function (a) {
                            return [
                              a.answer
                                ? _c("div", { key: a.id }, [
                                    _vm._v(_vm._s(a.answer)),
                                  ])
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.quData.analysis
              ? _c("div", [
                  _vm.sub
                    ? _c("div", [_vm._v("题目解析：")])
                    : _c("div", [_vm._v("大题解析：")]),
                  _c("div", {
                    staticClass: "asp",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$options.filters.formatRichText(_vm.quData.analysis)
                      ),
                    },
                  }),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }