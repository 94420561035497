var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("web-layout", {
    scopedSlots: _vm._u([
      {
        key: "main",
        fn: function () {
          return [
            _c("div", [_c("user-banner")], 1),
            _c("div", { staticClass: "uc-box" }, [
              _c(
                "div",
                { staticClass: "uc-menu" },
                [
                  _c(
                    "el-menu",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        "default-active": _vm.active,
                        "default-openeds": ["exam"],
                        "background-color": "#f8f8f8",
                        "unique-opened": "",
                        router: "",
                      },
                    },
                    [
                      _c(
                        "el-menu-item",
                        { attrs: { index: "/pages/uc/info" } },
                        [
                          _c("i", { staticClass: "el-icon-user" }),
                          _vm._v(" 个人资料 "),
                        ]
                      ),
                      _c(
                        "el-menu-item",
                        { attrs: { index: "/pages/uc/pass" } },
                        [
                          _c("i", { staticClass: "el-icon-lock" }),
                          _vm._v(" 密码安全 "),
                        ]
                      ),
                      _c(
                        "el-menu-item",
                        { attrs: { index: "/pages/uc/bind" } },
                        [
                          _c("i", { staticClass: "el-icon-connection" }),
                          _vm._v(" 账号绑定 "),
                        ]
                      ),
                      _vm.siteData.props.realPerson
                        ? _c(
                            "el-menu-item",
                            { attrs: { index: "/pages/uc/real" } },
                            [
                              _c("i", { staticClass: "el-icon-bank-card" }),
                              _vm._v(" 实名认证 "),
                            ]
                          )
                        : _vm._e(),
                      _c("el-menu-item", { attrs: { index: "/pages/uc/im" } }, [
                        _c("i", { staticClass: "el-icon-message" }),
                        _vm._v(" 站内信息 "),
                      ]),
                      _c(
                        "el-menu-item",
                        { attrs: { index: "/pages/uc/points" } },
                        [
                          _c("i", { staticClass: "el-icon-coin" }),
                          _vm._v(" 积分日志 "),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "uc-main" }, [_c("app-main")], 1),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }