<template>
  <div style="display: flex; flex-direction: row">
    <div style="width: 250px; flex: 0 0 250px">
      <result-left-nav :data="postForm" />
    </div>

    <div style="flex-grow: 1; padding-left: 20px">
      <!-- 题目列表 -->
      <result-qu-list ref="joinQuList" :data="postForm" />
    </div>
  </div>
</template>

<script>
import ResultLeftNav from '@/views/web/exam/components/QuResultPage/components/ResultLeftNav.vue'
import ResultQuList from '@/views/web/exam/components/QuResultPage/components/ResultQuList.vue'
export default {
  name: 'ResultDetail',
  components: { ResultQuList, ResultLeftNav },
  props: {
    data: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      postForm: {
        resultType: null,
        groupList: [],
      },
    }
  },
  created() {
    this.postForm = this.data
  },
  methods: {},
}
</script>
