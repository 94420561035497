<template>
  <div>
    <el-dialog :visible.sync="leaveDialog" :before-close="leaveBack" title="注意" width="30%">
      <span class="alert-text">您已经离开{{ leaveTimes }}次，离开{{ leaveCount }}次将自动交卷</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="leaveBack">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="msgDialog" :before-close="leaveBack" title="提示信息" width="30%">
      <span class="alert-text">本场考试已开启切屏监控，离开{{ leaveCount }}次将会强制交卷，请诚信考试！</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="leaveBack">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { leaveIncr } from '@/api/paper/exam'
import screenfull from 'screenfull'

export default {
  name: 'LeaveChecker',
  props: {
    leaveOn: Boolean,
    leaveCheck: {
      type: Number,
      default: 0,
    },
    leaveCount: {
      type: Number,
      default: 0,
    },
    paperId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      msgDialog: false,
      timer: null,
      leaveTime: 0,
      leaveDialog: false,
      leaveTimes: 0,
    }
  },

  watch: {
    leaveOn: {
      handler(val) {
        if (val) {
          this.msgDialog = true
          // 离开检测
          this.startCalc()
        }
      },
    },
  },

  created() {
    // 离开检测
    if (this.leaveOn) {
      this.startCalc()
      this.msgDialog = true
    }
  },

  mounted() {
    // 监听页面可见性
    window.addEventListener('visibilitychange', this.visibleChange)
  },
  beforeDestroy() {
    // 移除监听
    clearInterval(this.timer)
    window.removeEventListener('visibilitychange', this.visibleChange)
  },

  methods: {
    clearCalc() {
      // 重启计时器
      if (this.timer != null) {
        clearInterval(this.timer)
      }
    },

    // 开始倒计时
    startCalc() {
      this.clearCalc()
      this.timer = setInterval(this.fullChecker, 3000)
    },

    // 切换到全屏模式
    toggleFull() {
      if (!screenfull.isFullscreen) {
        screenfull.toggle()
        this.leaveTime = 0
        this.startCalc()
      }
    },

    // 定时检查全屏状态
    fullChecker() {
      // 已经切出全屏状态
      if (!screenfull.isFullscreen) {
        // 切到全屏
        screenfull.toggle()
        // 检查并保存
        this.saveIncr()
      }
    },

    // 回到正常考试
    leaveBack() {
      this.leaveDialog = false
      this.msgDialog = false
      this.toggleFull()
    },

    // 视图发生变化
    visibleChange() {
      const visible = document.visibilityState
      if (visible === 'hidden') {
        this.leaveTime = new Date().getTime()
      } else {
        this.saveIncr()
        // 离开返回，但没有达到最大
        this.$emit('back')
      }
    },

    saveIncr() {
      if (this.leaveTime === 0) {
        return
      }

      const that = this

      // 当前时间
      const nowTime = new Date().getTime()
      const leaveSec = (nowTime - this.leaveTime) / 1000

      if (leaveSec > this.leaveCheck) {
        // 暂停计数器
        this.clearCalc()
        // 同步到后台
        leaveIncr({ id: this.paperId }).then((res) => {
          const data = res.data

          that.leaveTimes = data.leaveTimes
          that.leaveDialog = true
          that.leaveTime = 0

          // 已经违约
          if (data.overLimit) {
            that.$emit('break')
          }
        })
      }
    },
  },
}
</script>

<style scoped>
.alert-text {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  line-height: 30px;
}
</style>
