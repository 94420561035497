var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      class: {
        "left-box": true,
        "left-box-normal": !_vm.scrolled,
        "left-box-scrolled": _vm.scrolled,
        "left-box-bottom": _vm.bottom,
      },
      staticStyle: { width: "250px" },
    },
    [
      _c("div", { staticClass: "group-card" }, [
        _c("div", { staticClass: "score-box" }, [
          _c("div", [
            _vm._v(" 试卷总分："),
            _c("i", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.postForm.totalScore) + "分"),
            ]),
          ]),
          _c("div", [
            _vm._v(" 及格分数："),
            _c("i", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.postForm.qualifyScore) + "分"),
            ]),
          ]),
          _c("div", [
            _vm._v(" 用户得分："),
            _c("i", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.postForm.userScore) + "分"),
            ]),
          ]),
          _c("div", [
            _vm._v(" 考试用时："),
            _c("i", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.postForm.userTime) + "分钟"),
            ]),
          ]),
          _c("div", [
            _vm._v(" 考试结果："),
            _c("i", { staticClass: "num" }, [
              _vm.postForm.valid
                ? _c("span", { staticStyle: { color: "#03dd6d" } }, [
                    _vm.postForm.passed
                      ? _c("span", { staticStyle: { color: "#03dd6d" } }, [
                          _vm._v("通过"),
                        ])
                      : _c("span", { staticStyle: { color: "#ff4b50" } }, [
                          _vm.postForm.reviewState === 0
                            ? _c(
                                "span",
                                { staticStyle: { color: "#ffba00" } },
                                [_vm._v("待阅卷")]
                              )
                            : _c("span", [_vm._v("未通过")]),
                        ]),
                  ])
                : _c("span", { staticStyle: { color: "#ff0000" } }, [
                    _vm._v("无效"),
                  ]),
            ]),
          ]),
          _vm.postForm.reviewer_dictText
            ? _c("div", [
                _vm._v(" 阅卷老师："),
                _c("i", { staticClass: "num" }, [
                  _vm._v(" " + _vm._s(_vm.postForm.reviewer_dictText) + " "),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._l(_vm.postForm.groupList, function (item) {
        return _c("div", { key: item.id }, [
          _c("div", { staticClass: "group-card" }, [
            _c(
              "div",
              {
                staticClass: "clearfix",
                staticStyle: { "line-height": "40px" },
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { "font-size": "14px", "font-weight": "700" },
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.goAnchor(item.id)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.title))]
                ),
                _c("el-divider"),
              ],
              1
            ),
            _c("div", { staticClass: "group-card-body" }, [
              _c("div", [
                _vm._v(" 共 "),
                _c("i", { staticClass: "num" }, [_vm._v(_vm._s(item.quCount))]),
                _vm._v(" 题，共 "),
                _c("i", { staticClass: "num" }, [
                  _vm._v(_vm._s(item.totalScore)),
                ]),
                _vm._v(" 分 "),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "card-box" },
              _vm._l(item.quList, function (card) {
                return _c(
                  "div",
                  {
                    key: card.id,
                    staticClass: "item",
                    on: {
                      click: function ($event) {
                        return _vm.goAnchor(card.id)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        class: {
                          num: true,
                          right: card.isRight,
                          error: !card.isRight,
                          current:
                            _vm.postForm.state == 1 && card.quType == "4",
                        },
                      },
                      [_vm._v(" " + _vm._s(card.sort) + " ")]
                    ),
                    _c("div", { staticClass: "flag" }, [
                      card.mark
                        ? _c("i", {
                            staticClass: "el-icon-s-flag",
                            staticStyle: { color: "#ff4b50" },
                          })
                        : _vm._e(),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }