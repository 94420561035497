<template>
  <el-select
    v-model="currentValue"
    :remote-method="fetchData"
    filterable
    remote
    clearable
    placeholder="选择或搜索课件"
    class="filter-item"
    size="small"
    @change="handlerChange"
    @clear="fetchData('')"
  >
    <el-option v-for="item in dataList" :key="item.id" :label="item.title" :value="item.id" />
  </el-select>
</template>

<script>
import { refFileList } from '@/api/client/course/course'

export default {
  name: 'CourseRefSelect',
  props: {
    courseId: String,
    value: String,
  },
  data() {
    return {
      // 下拉选项值
      dataList: [],
      currentValue: String,
    }
  },

  watch: {
    // 检测查询变化
    value: {
      handler() {
        this.currentValue = this.value
      },
    },
  },
  created() {
    this.currentValue = this.value
    this.fetchData()
  },
  methods: {
    fetchData(v) {
      refFileList(this.courseId, v).then((res) => {
        this.dataList = res.data
      })
    },
    handlerChange(e) {
      this.$emit('change', e)
      this.$emit('input', e)
    },
  },
}
</script>
